import React, { useState, useRef, useEffect } from "react";
import Menu from './sub/appbar';
import BasicinfoComponent from './sub/basicinfo';
import {
    MDBContainer, MDBInput
    , MDBDatepicker
    , MDBInputGroup,
    MDBCheckbox,
    MDBRadio, MDBBtn, MDBCollapse, MDBIcon, MDBSelect, MDBTextArea
} from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import services from '../helper/services';
import ErrorDialog from './sub/errordialog';
import { useSelector } from "react-redux";

import Multipleoption from './sub/multipleoption'


export default function Main() {
    const [expanded, setExpanded] = useState(false);
    const { state } = useLocation();
    let [selected, setSelected] = useState(null);
    const navigate = useNavigate();
    const error = useRef();
    const underlying = useRef();
    const familyhistory = useRef();
    const healthrisk = useRef();
    const medtherapy = useRef();
    const symptoms = useRef();
    const answer = useRef([]);
    const { t } = useTranslation('translations');
    const [basicInfo, setBasicInfo] = useState({});
    const updateBasicinfo = function (e) { e && setBasicInfo(e) }

    const title = {
        "6": "Quick health check",
        "5": "Basic symptoms checker",
        "2": "Holistic assessment",
        "4": "Basic symptoms checker",

    }

    const [transactionid, setTransactionid] = useState("");
    const [page, setPage] = useState({ note: "" });
    let topic = useRef({ topid_id: 0 });
    const [wait, setWait] = useState(false);
    let { self, minimal, version } = useParams();
    const [currentanswer, setCurrentAnswer] = useState({});
    const [currenRawtext, setCurrentRawtext] = useState("");
    const handleKeyDown = function (e) {
        e.target.style.height = 'inherit';
        e.target.style.height = `${e.target.scrollHeight}px`;
        // In case you have a limitation
        // e.target.style.height = `${Math.min(e.target.scrollHeight, limit)}px`;

        if (version == 5) {

            let value = e.target.value.trim();
            if (e.keyCode == 13) {

                if (value != "" && currenRawtext != value) {
                    setCurrentRawtext(value);
                    //processNLP(value, e)
                }
            }
        }
    }

    const onEnter = (e) => {
        if (e.key === "Enter") {
          e.target.blur();
        }
      };
    const handleChange = function (e) {


        if (version == 5) {

            let value = e.target.value.trim();

            if (value != "" && currenRawtext != value) {
                setCurrentRawtext(value);
                //processNLP(value, e)
            }

        }
    }
    const processNLP = async (value, e) => {
        if (version == 5) {
            setWait(true);
            const nlpitem = await services.processNLP(value);
            const items = nlpitem.formal_text_array.map(i => 'sn_' + i.t.sn);
            symptoms.current?.setSelectedvalues(items);
            e.target.value = e.target.value.trim();
            setWait(false);
        }

    }
    const handleExpandClick = (e) => {
        e.preventDefault();
        setExpanded(!expanded);

    };



    async function fetchuserprofile() {

        try {
            var res = await services.fetchuserprofile()
            setBasicInfo(res)
        } catch (e) {
            error?.current?.showerror(e);
        }

    }



    useEffect(() => {

        if (self === "self")
            fetchuserprofile();
        else if (state?.profile)
            setBasicInfo(state?.profile)




    }, []);
    async function handleSubmit(e, page) {
        var errormessage = "";
        e.preventDefault();
        if (wait) return;
        setWait(true);
        try {
            let data = services.getformdata(e.target, []);
            if (Object.keys(data).length !== 0 || version == 4) {

                /* if (version == 5) {
                    var items = symptoms.current?.getResult();
                    if (!items.Symptoms || items.Symptoms.length == 0) {
                        throw "No Symptom";
                    }
                } */
                if (version != 4) {
                    data = {
                        ...data,
                        bmi: (data.weight / (data.height * data.height / 10000)).toFixed(2),
                        ...underlying.current?.getResult(),
                        ...familyhistory.current?.getResult(),
                        ...healthrisk.current?.getResult(),
                        ...medtherapy.current?.getResult(),
                        ...symptoms.current?.getResult()
                    }
                }
                else {
                    data = {
                        ...data,
                        ...symptoms.current?.getResult()
                    }
                }



                if (Object.keys(data).length !== 0) {

                    data["minimal"] = minimal;
                    data["version"] = version;
                    try {
                        setWait(true);
                        var topic_id = 47
                        if (version == 3) {
                            topic_id = 50;
                        } else if (version == 2) {
                            topic_id = 53;
                        } else if (version == 4) {
                            topic_id = 54;
                        } else if (version == 5) {
                            topic_id = 55;
                        } else if (version == 6) {
                            topic_id = 57;
                        }

                        if (state?.topic_id) {
                            topic_id = state.topic_id;
                        }
                        var result = await services.saveCheckupResult("symptomsLogic", topic_id, [{ page: { id: 0 }, value: data, topic_id: topic_id }], basicInfo, (self == "self" ? "Y" : "N"));
                        setTransactionid(result.transaction_id)
                        setWait(false);
                        navigate("/checkupresult/" + result.transaction_id, { state: { showfeedback: true, hasPrevious: "Y", previous: "/" } });
                    } catch (ex) {
                        console.log(ex);
                        error.current.showerror(ex);
                        setWait(false);
                    }
                }

            }
            else {
                throw "Required field"
            }
        } catch (e) {
            console.log(e);
            error.current.showerror(e)
            setWait(false);
        }
    }
    return (<>
        <Menu requirelogin="true" allowPrevious={true} title={!basicInfo.gender ? t("Checkup") : (t("Checkup title") + t(basicInfo.gender == "M" ? "Male" : "Female") + ' ' + t("Agelabel", { age: (basicInfo.age == 0 ? window.calculateAgeMonth(basicInfo.dob) : basicInfo.age) }) + ' ' + t(basicInfo.age == 0 ? "M" : "Y"))} PreviousCallback={() => navigate(-1)} />
        <div className="container">
            <section className='pt-4 p-md-4'>
                <ErrorDialog ref={error} />
                {!basicInfo.gender && <BasicinfoComponent callback={updateBasicinfo} />}
                {basicInfo.gender &&
                    <>
                        <div className="mx-0 mx-sm-auto">
                            <form className="card" onSubmit={(e) => handleSubmit(e, page)}>
                                <div className="card-body px-1 px-mb-2">
                                    <div className="text-center">
                                        <i className="fas fa-heart-pulse fa-4x mb-3 text-primary"></i>
                                        <p>
                                            {self === "self" && t("Self assessment") + " " + t((state?.title ?? (version ? title[version] : "Checkup")) + " Topic")}
                                            {self !== "self" && t("Not-self assessment") + " " + t((state?.title ?? (version ? title[version] : "Checkup")) + " Topic")}
                                        </p>

                                    </div>

                                    <hr />

                                    <div className="px-md-4 row row-cols-1 row-cols-sm-2 row-cols-md-2 g-3 justify-content-center" action="" autoComplete="off">
                                        <div className="col">
                                            {!["4", "5", "6"].includes(version) && <div className="row justify-content-center">
                                                <div className="col col-md-10">
                                                    {minimal != "minimal" && <p className="fw-bold mb-2">{t("body measurement")}</p>}
                                                    <div className="ms-mb-3 mb-3">
                                                        <div className="form-outline mb-3">
                                                            <MDBInput
                                                                required
                                                                type="number"
                                                                name="weight"
                                                                label={<><label className="form-label">{t("weight")}<span className="text-danger">*</span></label></>}
                                                                min="0"
                                                                max="200"
                                                                step="0.01"
                                                                onKeyUp={onEnter}
                                                            />
                                                        </div>

                                                        <div className="form-outline mb-3">
                                                            <MDBInput
                                                                required
                                                                type="number"
                                                                name="height"
                                                                label={<><label className="form-label">{t("height")}<span className="text-danger">*</span></label></>}
                                                                min="0"
                                                                max="250"
                                                                step="0.01"
                                                                onKeyUp={onEnter}
                                                            />
                                                        </div>
                                                        <input type="hidden" name="bmi" />
                                                        {(minimal != "minimal" || ["6"].includes(version)) && <div className="form-outline mb-3">
                                                            <MDBInput
                                                                max="100"
                                                                type="number"
                                                                name="waist"
                                                                required={["6"].includes(version)}
                                                                label={<><label className="form-label">{t("waist circumference (inch)")}{["6"].includes(version) && <span className="text-danger">*</span>}</label></>}
                                                                step="0.01"
                                                                onKeyUp={onEnter}
                                                            />
                                                        </div>}
                                                    </div>
                                                </div>
                                            </div>}
                                            {["2", "4", "5"].includes(version) && <div className="row justify-content-center" style={version == 5 ? { display: "none" } : {}}>
                                                <div className="col col-md-10">
                                                    {minimal != "minimal" && <p className="fw-bold mb-2">{t("currentsymptoms")}</p>}
                                                    <div className="ms-mb-3 mb-3">
                                                        <Multipleoption name="Symptoms" label="currentsymptoms" ref={symptoms} minimal={minimal == "minimal"} search={true} basicInfo={basicInfo} />
                                                    </div>
                                                </div>
                                            </div>}
                                            {minimal != "minimal" && <hr />}
                                            {!["4", "5"].includes(version) && <div className="row justify-content-center">
                                                <div className="col col-md-10">
                                                    {(minimal != "minimal" || ["6"].includes(version)) && <p className="fw-bold mb-2">{t("Underlying")}</p>}
                                                    <div className="ms-mb-3 mb-3">
                                                        <Multipleoption name="underlying" label="Underlying" ref={underlying} minimal={minimal == "minimal"} basicInfo={basicInfo} ischeckbox={["6"].includes(version)} />
                                                    </div>
                                                </div>
                                            </div>}
                                            {minimal != "minimal" && <hr />}
                                            {!["4", "5"].includes(version) && <div className="row justify-content-center">
                                                <div className="col col-md-10">
                                                    {(minimal != "minimal" || ["6"].includes(version)) && <p className="fw-bold mb-2">{t("Family History")}</p>}
                                                    <div className="ms-mb-3 mb-3">
                                                        <Multipleoption name="familyhistory" label="Family History" ref={familyhistory} minimal={minimal == "minimal"} basicInfo={basicInfo} ischeckbox={["6"].includes(version)} />
                                                    </div>
                                                </div>
                                            </div>}
                                            {minimal != "minimal" && <hr />}
                                            {!["4", "5"].includes(version) && <div className="row justify-content-center">
                                                <div className="col col-md-10">
                                                    {(minimal != "minimal" || ["6"].includes(version)) && <p className="fw-bold mb-2">{t("health risk")}</p>}
                                                    <div className="ms-mb-3 mb-3">
                                                        <Multipleoption name="healthrisk" label="health risk" ref={healthrisk} minimal={minimal == "minimal"} basicInfo={basicInfo} ischeckbox={["6"].includes(version)} />
                                                    </div>
                                                </div>
                                            </div>}

                                            {["2"].includes(version) && <div className="row justify-content-center">
                                                <div className="col col-md-10">
                                                    {(minimal != "minimal" || ["6"].includes(version)) && <p className="fw-bold mb-2">{t("medtherapy")}</p>}
                                                    <div className="ms-mb-3 mb-3">
                                                        <Multipleoption name="medtherapy" label="medtherapy" ref={medtherapy} minimal={minimal == "minimal"} basicInfo={basicInfo} />
                                                    </div>
                                                </div>
                                            </div>}

                                            {["6"].includes(version) && <div className="row justify-content-center">
                                                <div className="col col-md-10">
                                                    {(minimal != "minimal" || ["6"].includes(version)) && <p className="fw-bold mb-2">{t("body measurement")}</p>}
                                                    <div className="ms-mb-3 mb-3">
                                                        <div className="form-outline mb-3">
                                                            <MDBInput
                                                                required
                                                                type="number"
                                                                name="weight"
                                                                label={<><label className="form-label">{t("weight")}<span className="text-danger">*</span></label></>}
                                                                min="0"
                                                                max="200"
                                                                step="0.01"
                                                                onKeyUp={onEnter}
                                                            />
                                                        </div>

                                                        <div className="form-outline mb-3">
                                                            <MDBInput
                                                                required
                                                                type="number"
                                                                name="height"
                                                                label={<><label className="form-label">{t("height")}<span className="text-danger">*</span></label></>}
                                                                min="0"
                                                                max="250"
                                                                step="0.01"
                                                                onKeyUp={onEnter}
                                                            />
                                                        </div>
                                                        <input type="hidden" name="bmi" />
                                                        {(minimal != "minimal" || ["6"].includes(version)) && <div className="form-outline mb-3">
                                                            <MDBInput
                                                                max="100"
                                                                type="number"
                                                                name="waist"
                                                                required={["6"].includes(version)}
                                                                label={<><label className="form-label">{t("waist circumference (inch)")}{["6"].includes(version) && <span className="text-danger">*</span>}</label></>}
                                                                step="0.01"
                                                                onKeyUp={onEnter}
                                                            />
                                                        </div>}
                                                    </div>
                                                </div>
                                            </div>}
                                            {minimal == "minimal" && <div className="row justify-content-center">
                                                <div className="col col-md-10"><div className="ms-mb-3 mb-3"><div className="form-outline mb-3">
                                                    {["6"].includes(version) && <p className="fw-bold mb-2">{t("other header")}</p>}
                                                    <MDBTextArea
                                                        onKeyDown={handleKeyDown}
                                                        onBlur={handleChange}
                                                        onKeyUp={onEnter}
                                                        name="checkupother"
                                                        label={t(version == 5 ? "other_nlp" : "other")}

                                                    />
                                                </div></div></div></div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer text-end">

                                    <MDBBtn type="submit" disabled={wait}>{t("Submit")}</MDBBtn>
                                </div>
                            </form >
                        </div >

                    </>

                }
            </section >
        </div >


    </>
    );
}