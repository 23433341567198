import React, { forwardRef, useImperativeHandle, useRef, useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import "react-bootstrap-typeahead/css/Typeahead.css";
import {
    MDBBadge, MDBListGroup, MDBListGroupItem, MDBIcon, MDBBtn
    , MDBAccordion, MDBAccordionItem,
    MDBTable, MDBTableHead, MDBTableBody
} from 'mdb-react-ui-kit';
import ErrorDialog from './errordialog';
import services from "../../helper/services";
import moment from 'moment';
import 'moment/locale/th'

import { useSelector } from "react-redux";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AdapterDateFnsBuddhist from '../../helper/date-fns-be';
import { th, enUS } from 'date-fns/locale';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Historypersonalhealthgroup from './historypersonalhealthgroup'

const component = forwardRef((props, ref) => {
    const navigate = useNavigate();
    const error = useRef();

    const { t } = useTranslation('translations');
    const [wait, setWait] = useState(false);
    const [result, setResult] = useState([]);
    const [min_date, setmin_date] = useState();
    const [value, setValue] = useState(new Date());
    const dateAdapter = useSelector(state => state.lang);

    function handleChange(newValue) {
        setValue(newValue)
        getHistory({
            dateFromSearch: moment(newValue).startOf('month').format('YYYY-MM-DD'),
            dateToSearch: moment(newValue).endOf('month').add(1, 'd').format('YYYY-MM-DD'),
            hn: props.hn
        });
    }
    async function getHistory(data) {
        if (wait) return;
        setWait(true);
        try {
            var result = await services.searchPersonalHealth(data);
            setmin_date(new Date(result.min_date));
            const recorded = result.personalhealthList.map(e => ({ ...e, record_datetime: new Date(e.record_date), record_date: new Date(new Date(e.record_date).setHours(0, 0, 0, 0)) }));
            setResult(recorded);
            setWait(false);

        } catch (e) {
            setWait(false);
            error.current.showerror(e);
        }

    }
    useEffect(() => {

        getHistory({
            dateFromSearch: moment(value).startOf('month').format('YYYY-MM-DD'),
            dateToSearch: moment(value).endOf('month').add(1, 'd').format('YYYY-MM-DD'),
            hn: props.hn

        });


    }, []);

    const group_time = (obj) => {
        var main_item = services.groupBy(obj, "record_datetime");
        const keys = Object.keys(main_item).sort(function (a, b) { return new Date(b) > new Date(a) })
        const items = [];
        for (var key of keys) {
            const initialValue = {};
            let item = main_item[key].reduce((obj, item) => {
                return {
                    ...obj,
                    [item["name"]]: item.value,
                };
            }, initialValue);
            items.push({ record_datetime: key, ...item });
        }
        return items;
    }
    function toBuddhistYear(moment, format) {
        var christianYear = moment.format('YYYY')
        var buddhishYear = (parseInt(christianYear) + 543).toString()
        return moment
            .format(format.replace('YYYY', buddhishYear).replace('YY', buddhishYear.substring(2, 4)))
            .replace(christianYear, buddhishYear);
    }

    function generateTree(obj) {
        var main = services.groupBy(obj, "record_date");
        var items = Object.keys(main).sort(function (a, b) { return new Date(b) > new Date(a) })
        //var items = group_time(obj);
        return <>
            <MDBTable align='middle' hover bordered className="table-sm" responsive>
                <MDBTableHead>
                    <tr className='table-info'>
                        <th scope='col' className="text-center px-md-2 py-md-4 align-top" style={{ width: "1px" }}><MDBIcon fas icon="trash-alt" size='lg' /></th>
                        <th scope='col' className="text-center p-md-4 col-1 align-top">{t("r_datetime")}</th>
                        <th scope='col' className="p-md-4 text-end col-1 align-top">{t("bs")}</th>
                        <th scope='col' className="p-md-4 text-end col-1 align-top">{t("sbp")}</th>
                        <th scope='col' className="p-md-4 text-end col-1 align-top">{t("dbp")}</th>
                        <th scope='col' className="p-md-4 text-end col-1 align-top">{t("pulse")}</th>
                        <th scope='col' className="p-md-4 align-top">{t("comment")}</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {items.map((el, j) => <Historypersonalhealthgroup key={j} el={el} main={main} hn={props.hn} />

                    )}

                </MDBTableBody>
            </MDBTable></>

    }


    return (
        <div className="container">

            <div className="row justify-content-center row-cols-md-3   row-cols-xl-5  row-cols-8 ">
                <ErrorDialog ref={error} />
                {< div className=" mb-3 text-center">
                    {dateAdapter !== "th" && <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enUS} >
                        <label htmlFor="searchdate" className="form-label">{t("Selectdate")}</label>
                        <div><DesktopDatePicker
                            views={['month', 'year']}
                            minDate={min_date}
                            maxDate={new Date()}
                            onChange={handleChange}
                            value={value}
                            slotProps={{ textField: { sx: { width: "100%" }, variant: 'outlined', size: 'small', id: "searchdate" } }}
                            format="MMMM yyyy"
                        /></div>

                    </LocalizationProvider>}
                    {dateAdapter == "th" && <LocalizationProvider dateAdapter={AdapterDateFnsBuddhist} adapterLocale={th} >
                        <label htmlFor="searchdate" className="form-label">{t("Selectdate")}</label>
                        <div><DesktopDatePicker
                            views={['month', 'year']}
                            minDate={min_date}
                            maxDate={new Date()}
                            onChange={handleChange}
                            value={value}
                            slotProps={{ textField: { sx: { width: "100%" }, variant: 'outlined', size: 'small', id: "searchdate" } }}

                            format="MMMM yyyy"
                        /></div>

                    </LocalizationProvider>}
                </div>
                }
            </div>

            {result.length > 0 && <>
                <MDBAccordion initialActive={1}>
                    <MDBAccordionItem collapseId={1} headerTitle={<><b className="text-default"><MDBIcon fas icon="info-circle" color="danger" className="pe-2" /><span dangerouslySetInnerHTML={
                        { __html: t("Personalhealth Remark", { interpolation: { escapeValue: false } }) }
                    }></span></b></>} className="mb-3">

                        <div className="col-md-12 mt-0">
                            <span className="badge badge-light text-wrap text-start w-100"><span dangerouslySetInnerHTML={
                                { __html: t("Personalhealth remark desc" + (process.env.REACT_APP_HOSPITAL ?? ""), { interpolation: { escapeValue: false } }) }
                            }></span></span>
                        </div>

                    </MDBAccordionItem>
                </MDBAccordion>
                <div className="row  row-cols-md-10 row-cols-10 overflow-auto">

                    {generateTree(result)}

                </div>


            </>}
        </div>
    );
});



export default component;