import React, { forwardRef, useImperativeHandle, useRef, useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import "react-bootstrap-typeahead/css/Typeahead.css";
import {
    MDBBadge, MDBListGroup, MDBListGroupItem, MDBIcon, MDBBtn

} from 'mdb-react-ui-kit';
import ErrorDialog from './errordialog';
import services from "../../helper/services";
import moment from 'moment';


import { useSelector } from "react-redux";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AdapterDateFnsBuddhist from '../../helper/date-fns-be';
import { th, enUS } from 'date-fns/locale';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import HistoryGroup from './historygroup'

const component = forwardRef((props, ref) => {
    const navigate = useNavigate();
    const error = useRef();
    const [urgentcolor] = useState(["", "success", "warning", "danger"]);
    const { t } = useTranslation('translations');
    const [wait, setWait] = useState(false);
    const [result, setResult] = useState([]);

    const [value, setValue] = useState(new Date());
    const dateAdapter = useSelector(state => state.lang);

    function handleChange(newValue) {
        setValue(newValue)
        getHistory({
            dateFromSearch: moment(newValue).startOf('month').format('YYYY-MM-DD'),
            dateToSearch: moment(newValue).endOf('month').add(1, 'd').format('YYYY-MM-DD')
            , checkup: props.checkup
        });
    }
    async function getHistory(data) {
        if (wait) return;
        setWait(true);
        try {
            var result = await services.searchResult(data);
            for (const item of result) {
                try {
                    //item.logic_result = JSON.parse(item.logic_result);
                    item.urgency = item.logic_result.urgency;
                } catch (ex) { }
            }
            setResult(result);
            setWait(false);

        } catch (e) {
            setWait(false);
            error.current.showerror(e);
        }

    }
    useEffect(() => {

        getHistory({
            dateFromSearch: moment().startOf('month').format('YYYY-MM-DD'),
            dateToSearch: moment().endOf('month').add(1, 'd').format('YYYY-MM-DD')
            , checkup: props.checkup
        });


    }, []);



    function generateTree(obj) {
        var main = services.groupBy(obj, "createdate");
        var items = Object.keys(main).sort(function (a, b) { return b.toString().localeCompare(a.toString()) })

        return <div className="row justify-content-center">{items.map((el, j) => <HistoryGroup key={j} el={el} main={main} checkup={props.checkup} />

        )}</div>

    }


    return (
        <div className="container-fluid col col-xl-6 col-lg-8 col-md-10 text-start">
            <ErrorDialog ref={error} />
            {< div className=" mb-3 text-center">
                {dateAdapter !== "th" && <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enUS} >
                    <label htmlFor="searchdate" className="form-label">{t("Selectdate")}</label>
                    <div><DesktopDatePicker
                        views={['month', 'year']}
                        minDate={new Date(((new Date().getFullYear()) - 150).toString() + '-01-01')}
                        onChange={handleChange}
                        value={value}
                        slotProps={{ textField: { sx: { width: "100%" }, variant: 'outlined', size: 'small', id: "searchdate" } }}
                        format="MMMM yyyy"
                    /></div>

                </LocalizationProvider>}
                {dateAdapter == "th" && <LocalizationProvider dateAdapter={AdapterDateFnsBuddhist} adapterLocale={th} >
                    <label htmlFor="searchdate" className="form-label">{t("Selectdate")}</label>
                    <div><DesktopDatePicker
                        views={['month', 'year']}
                        minDate={new Date(((new Date().getFullYear()) - 150).toString() + '-01-01')}
                        maxDate={new Date()}
                        onChange={handleChange}
                        value={value}
                        slotProps={{ textField: { sx: { width: "100%" }, variant: 'outlined', size: 'small', id: "searchdate" } }}

                        format="MMMM yyyy"
                    /></div>

                </LocalizationProvider>}
            </div>
            }
            {result.length > 0 && <>

                <div>
                    {generateTree(result)}
                </div>


            </>}
        </div>
    );
});



export default component;