import ErrorDialog from './sub/errordialog';
import {
    MDBBtn,
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBRow,
    MDBCol,
    MDBIcon,
    MDBInput,
    MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
}
    from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom'
import React, { useCallback, useState, useRef, useEffect } from "react";
import services from '../helper/services';
import { GoogleLogin } from '@react-oauth/google';
import GoogleAuth from './sub/googleauth';
import AppleAuth from './sub/appleauth';

function Login() {
    const navigate = useNavigate();
    const error = useRef();
    const { t, i18n } = useTranslation('translations');
    function changeLanguageHandler(lang) {
        i18n.changeLanguage(lang)

    }
    const [searchParams, setSearchParams] = useSearchParams();

    const [termModal, setTermModal] = useState(false);
    const [privacyModal, setPrivacyModal] = useState(false);

    useEffect(() => {
        if (searchParams && searchParams.get("modal"))
            setTimeout(() => {
                toggleShow(searchParams.get("modal"))
            }, 500);
        if (searchParams && searchParams.get("token")) {
            window.sessionStorage.setItem("token", searchParams.get("token"));
            if (searchParams && searchParams.get("returnurl"))
                navigate(searchParams.get("returnurl"))
            else
                navigate("/index")
        }
    }, []);
    const toggleShow = (modal) => {
        if (modal.toLowerCase() === "term")
            setTermModal(!termModal);
        else
            setPrivacyModal(!privacyModal);
    }
    services.logout();

    const googlesignin = async (jwtToken) => {


        try {
            const res = await services.googlesignin(jwtToken);

            if (res.data.message == "notverified") {
                error.current.showmessage("account not verified", () => { navigate("/verify") })
            } else if (res.data.message == "active") {
                window.sessionStorage.setItem("token", res.data.access_token);
                if (searchParams && searchParams.get("returnurl"))
                    navigate(searchParams.get("returnurl"))
                else
                    navigate("/index")
            }
            else if (res.data.message == "new") {
                const token = { token: jwtToken, platform: "google" };
                const entry = await services.getpersonalentry(token);
                navigate("/externalregister", { state: { token: token, socialpersonalentry: entry } })
            }
        } catch (e) {
            error.current.showerror(e)
        }




    }

    const applesignin = async (jwtToken, user) => {


        try {
            const res = await services.applesignin(jwtToken);

            if (res.data.message == "notverified") {
                error.current.showmessage("account not verified", () => { navigate("/verify") })
            } else if (res.data.message == "active") {
                window.sessionStorage.setItem("token", res.data.access_token);
                if (searchParams && searchParams.get("returnurl"))
                    navigate(searchParams.get("returnurl"))
                else
                    navigate("/index")
            }
            else if (res.data.message == "new") {
                const token = { token: jwtToken, platform: "apple" };
                const entry = { email: user.email };
                navigate("/externalregister", { state: { token: token, socialpersonalentry: entry } })
            }
        } catch (e) {
            error.current.showerror(e)
        }




    }
    const handlesubmit = (e) => {
        e.preventDefault();
        const items = {};
        for (const pair of (new FormData(e.target)).entries()) {

            items[pair[0]] = pair[1];
        }

        fetch(process.env.REACT_APP_DOMAIN + "apismd/smd_c/user/login", {
            method: "POST",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(items),
        })
            .then((res) => res.json())
            .then((res) => {

                if (res.success) {
                    if (res.data.message == "notverified") {
                        error.current.showmessage("account not verified", () => { navigate("/verify") })
                    } else {
                        window.sessionStorage.setItem("token", res.data.access_token);
                        if (searchParams && searchParams.get("returnurl"))
                            navigate(searchParams.get("returnurl"))
                        else
                            navigate("/index")
                    }
                } else {
                    error.current.showerror(res.error.map(e => e.text || e.message || e).join(' '))
                }

            });
    };
    return (
        <>
            {(searchParams.get("token") ?? '') == '' && <MDBContainer className="my-5">
                <MDBRow className='g-0'>
                    <MDBCol xl='3' ></MDBCol>
                    <MDBCol md='12' xl='6' >
                        <MDBCard>

                            <MDBCardBody className='d-flex flex-column'>
                                <div className='d-flex justify-content-between mb-2'>
                                    <div className='d-flex flex-row mt-2'>
                                        <img
                                            src={process.env.PUBLIC_URL + '/logo192.png'}
                                            height='48'
                                            alt=''
                                            loading='lazy'
                                        />

                                    </div>
                                    <MDBDropdown group className='shadow-0' >
                                        <MDBDropdownToggle color='link' >{i18n.language.toLowerCase().startsWith("th") ? "TH" : "EN"}</MDBDropdownToggle>
                                        <MDBDropdownMenu responsive='end'>
                                            <MDBDropdownItem link onClick={(e) => changeLanguageHandler("en")}>EN</MDBDropdownItem>
                                            <MDBDropdownItem link onClick={(e) => changeLanguageHandler("th")}>TH</MDBDropdownItem>

                                        </MDBDropdownMenu>
                                    </MDBDropdown>
                                </div>
                                <ErrorDialog ref={error} />
                                <h5 className="fw-normal mt-2 mb-4 pb-3" style={{ letterSpacing: '1px' }}>{t('Sign into your account' + (process.env.REACT_APP_HOSPITAL ?? ""))}</h5>

                                <form onSubmit={handlesubmit}>
                                    <MDBInput wrapperClass='mb-4' label={t('Enter Email or Username')} id='email' type='text' name="user" size="lg" noValidate />
                                    <MDBInput wrapperClass='mb-4' label={t('Enter Password')} id='password' type='password' name='password' size="lg" />

                                    <MDBBtn className="mb-4 px-5" size='lg'>{t('login')}</MDBBtn>
                                </form>
                                <a className="small text-link" href="/forgotpassword" >{t('Forgot password?')}</a>
                                <p className="mb-3 pb-lg-2" >{t(`Don't have an account?`)} <a href='/register' className='text-link'>{t(`Register here`)}</a></p>


                                <div className='justify-content-center text-center mb-3'>
                                    <div className="row">
                                        <div className="col">
                                            <hr style={{ border: '1px solid #61677D' }} />
                                        </div>
                                        <div className="col align-self-center">
                                            <p className="text-center fw-bold mx-3 mb-0 text-muted">{t("OR")}</p>
                                        </div>
                                        <div className="col">
                                            <hr style={{ border: '1px solid #61677D' }} />
                                        </div>
                                    </div>
                                </div>
                                {!window.isinAppBrowser() ?
                                    <> <div className='text-center mb-3'>
                                        <div className="d-flex justify-content-center text-center row my-2">
                                            <div className='col-auto'> <GoogleAuth onSuccess={googlesignin} /></div>
                                        </div>
                                        <div className="d-flex justify-content-center text-center row  my-2">
                                            <div className='col-auto'>
                                                <AppleAuth onSuccess={applesignin} label={t("Sign in with Apple")} />
                                            </div>
                                        </div>
                                    </div>
                                    </> :
                                    <>
                                        <div className='justify-content-center text-center  text-muted fw-bold mb-3'>
                                            <p>{t("in app browser not support")}</p>
                                        </div>
                                    </>}




                                <div className='d-flex flex-row justify-content-start'>
                                    <a href="#" style={{ color: '#3461FD' }} onClick={(e) => e.preventDefault() | toggleShow("Term")} className="small text-muted me-4">{t(`Terms of Service`)}</a>
                                    <a href="#" style={{ color: '#3461FD' }} onClick={(e) => e.preventDefault() | toggleShow("Privacy")} className="small text-muted">{t(`Privacy policy`)}</a>
                                </div>

                            </MDBCardBody>

                        </MDBCard>
                    </MDBCol>
                    <MDBCol xl='3' ></MDBCol>
                </MDBRow>

                <MDBModal open={termModal} setOpen={setTermModal} tabIndex='-1'>
                    <MDBModalDialog className='modal-fullscreen'>
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle>{t(`Terms of Service`)}</MDBModalTitle>
                                <MDBBtn className='btn-close' color='none' onClick={() => toggleShow("Term")}></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody dangerouslySetInnerHTML={
                                { __html: t("TOS" + (process.env.REACT_APP_HOSPITAL ?? ""), { interpolation: { escapeValue: false } }) }
                            }></MDBModalBody>

                            <MDBModalFooter className='justify-content-between'>
                                <MDBDropdown group className='shadow-0'>
                                    <MDBDropdownToggle color='link' >{i18n.language.toLowerCase().startsWith("th") ? "TH" : "EN"}</MDBDropdownToggle>
                                    <MDBDropdownMenu responsive='end'>
                                        <MDBDropdownItem link onClick={() => changeLanguageHandler("en")}>EN</MDBDropdownItem>
                                        <MDBDropdownItem link onClick={() => changeLanguageHandler("th")}>TH</MDBDropdownItem>

                                    </MDBDropdownMenu>
                                </MDBDropdown>

                                <MDBBtn color='secondary' onClick={() => toggleShow("Term")}>
                                    {t(`OK`)}
                                </MDBBtn>

                            </MDBModalFooter>
                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal>

                <MDBModal open={privacyModal} setOpen={setPrivacyModal} tabIndex='-1'>
                    <MDBModalDialog className='modal-fullscreen'>
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle>{t(`Privacy policy`)}</MDBModalTitle>
                                <MDBBtn className='btn-close' color='none' onClick={() => toggleShow("Privacy")}></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody dangerouslySetInnerHTML={
                                { __html: t("Privacy" + (process.env.REACT_APP_HOSPITAL ?? ""), { interpolation: { escapeValue: false } }) }
                            }></MDBModalBody>

                            <MDBModalFooter className='justify-content-between'>
                                <MDBDropdown group className='shadow-0'>
                                    <MDBDropdownToggle color='link' >{i18n.language.toLowerCase().startsWith("th") ? "TH" : "EN"}</MDBDropdownToggle>
                                    <MDBDropdownMenu responsive='end'>
                                        <MDBDropdownItem link onClick={() => changeLanguageHandler("en")}>EN</MDBDropdownItem>
                                        <MDBDropdownItem link onClick={() => changeLanguageHandler("th")}>TH</MDBDropdownItem>

                                    </MDBDropdownMenu>
                                </MDBDropdown>

                                <MDBBtn color='secondary' onClick={() => toggleShow("Privacy")}>
                                    {t(`OK`)}
                                </MDBBtn>

                            </MDBModalFooter>
                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal>
            </MDBContainer>}</>
    );
}

export default Login;