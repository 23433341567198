import React from 'react';
import {
    MDBIcon
} from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';

export default function ScoreRating(props) {
    const { t } = useTranslation('translations');

    return (<>
        <div className="my-2">
            <span className="badge badge-light">{t("Interpretation")}</span> <br />
            <span className="badge badge-light mt-2">{t("star_desc")}</span><br />
            <span className="ms-2 badge rounded-pill badge-light text-wrap text-start"><MDBIcon far icon="star"  /><MDBIcon fas icon="minus" className='mx-1' /><MDBIcon far icon="star" /><MDBIcon far icon="star" /><MDBIcon far icon="star" /><MDBIcon far icon="star" /><MDBIcon far icon="star" /></span>
            <br /><span className="badge badge-light mt-2">{t("color_desc")}</span><br />
            <span className="ms-2 badge rounded-pill badge-light text-wrap text-start">
            <MDBIcon fas icon="star" className='me-2 text-danger' /><span dangerouslySetInnerHTML={
                    { __html: t("red-star_desc", { interpolation: { escapeValue: false } }) }
                }></span></span>
            <br />
            <span className="ms-2 badge rounded-pill badge-light text-wrap text-start">
            <MDBIcon fas icon="star" className='me-2 text-success' /><span dangerouslySetInnerHTML={
                    { __html: t("green-star_desc", { interpolation: { escapeValue: false } }) }
                }></span></span>
        </div>
    </>
    );
}