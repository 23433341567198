import React, { useState, useRef, useEffect } from "react";
import Menu from './sub/appbar';
import BasicinfoComponent from './sub/basicinfo';
import {
    MDBContainer, MDBInput
    , MDBDatepicker
    , MDBInputGroup,
    MDBCheckbox,
    MDBRadio, MDBBtn, MDBCollapse, MDBIcon
} from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import services from '../helper/services';
import ErrorDialog from './sub/errordialog';
import { useSelector } from "react-redux";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AdapterDateFnsBuddhist from '../helper/date-fns-be';
import { th, enUS } from 'date-fns/locale';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

export default function Main() {
    const [expanded, setExpanded] = useState(false);
    const { state } = useLocation();
    let [selected, setSelected] = useState(null);
    const navigate = useNavigate();
    const error = useRef();
    const answer = useRef([]);
    const { t } = useTranslation('translations');
    const [basicInfo, setBasicInfo] = useState({});
    const updateBasicinfo = function (e) { e && setBasicInfo(e) }

    const [value, setValue] = React.useState([]);

    const [transactionid, setTransactionid] = useState("");
    const [page, setPage] = useState({ note: "" });
    let topic = useRef({ topid_id: 0 });
    const [wait, setWait] = useState(false);
    let { self, id } = useParams();
    const [currentanswer, setCurrentAnswer] = useState({});

    const dateAdapter = useSelector(state => state.lang);
    const handleExpandClick = (e) => {
        e.preventDefault();
        setExpanded(!expanded);

    };

    async function fetchuserprofile() {

        try {
            var res = await services.fetchuserprofile()
            setBasicInfo(res)
        } catch (e) {
            error.current.showerror(e);
        }

    }

    useEffect(() => {
        if (Object.keys(currentanswer).length === 0) return;
        for (const item of Object.keys(currentanswer)) {
            var itemvalue = currentanswer[item];
            var itemnode = document.querySelector(`[name='${item}']`);
            if (!itemnode) continue;
            if (["radio", "checkbox"].includes(itemnode.type)) {



                var node = document.querySelector(`[name='${item}'][value="${itemvalue}"]`);
                if (node) {

                    node.click();
                    node.checked = true;
                }
                else {
                    node = document.querySelector(`[name='${item}'][value="other"]`);
                    if (node && itemvalue !== "") {

                        if (currentanswer[item + "checked"] == 1) {
                            node.click();
                            node.checked = true;
                        }

                        var textnode = (document.querySelector(`textarea[name='${item}']`) ?? document.querySelector(`input[type='text'][name='${item}']`));
                        if (textnode) textnode.value = itemvalue;
                    }
                }




            } else {
                document.querySelector(`[name='${item}']`).value = itemvalue;
            }
        }
    }, [currentanswer]);
    async function getPreviousPage() {
        if (answer.current.length > 0) {

            var currentanswer = answer.current[answer.current.length - 1];
            topic.current.topid_id = currentanswer.topic_id;
            if (!currentanswer.page.items) return;
            else {
                answer.current.pop();

            }
            setPage(currentanswer.page);
            setWait(false);
            setCurrentAnswer(currentanswer.value);

        }
        else {
            navigate("/")
        }
    };
    async function getNextPage(selecteddestination) {
        if (!basicInfo.gender) return;
        setWait(true);
        if (selecteddestination) {
            if (selecteddestination.destination_topic_id) topic.current.topid_id = selecteddestination.destination_topic_id;
            if (selecteddestination.destination_type == "page") {
                var page = await services.getPageById(topic.current.topid_id, selecteddestination.destination_id, basicInfo);
                if (page) {
                    setPage(page);
                    setWait(false);
                }
            } else if (selecteddestination.destination_type == "logic") {

                setWait(true);
                try {
                    var result = await services.saveSymptomCheckerResult(selecteddestination.destination_logic, topic.current.topid_id, answer.current, basicInfo, (self == "self" ? "Y" : "N"), state?.checkup ?? "N");
                    setTransactionid(result.transaction_id)
                    setWait(false);
                    navigate("/result/" + result.transaction_id, { state: { showfeedback: true, hasPrevious: "Y", previous: "/" } });
                } catch (ex) {
                    console.log(ex);
                    error.current.showerror(ex);
                    setWait(false);
                }


            }
        }

    }

    async function handleSubmit(e, page) {
        var errormessage = "";
        try {
            e.preventDefault();

            if (wait) return;
            setWait(true);

            if (page.bindingscript) {
                const items = {};
                for (const pair of (new FormData(e.target)).entries()) {
                    if (pair[0].startsWith("date-") && value[pair[0]]) {
                        pair[1] = value[pair[0]].toLocaleDateString('en-CA');
                        items[pair[0].replace("date-", "")] = pair[1];
                        continue;
                    }
                    items[pair[0]] = pair[1];
                }
                var criteriafunction = Object.keys(items);
                var binding = new Function(criteriafunction, `${page.bindingtarget ? `try{document.getElementsByName('${page.bindingtarget}')[0].value =` : ""}${page.bindingscript}}catch(e){console.log(e);}`);
                binding(...Object.values(items));

            }

            let data = services.getformdata(e.target, value);

            if (data.topiclist == "other") {
                if (null !== selected)
                    data.topiclist = selected.value;
                else {
                    data = {};
                }
            }




            console.logitem(data);
            if (Object.keys(data).length !== 0) {

                answer.current.push({ page: page, value: data, topic_id: topic.current.topid_id });
                var anwerlist = answer.current.filter(ans => ans.topic_id == page.topic.topic_id).map(ans => ans.value);
                var selecteddestination = await services.getDestination(page.destinations, basicInfo, anwerlist)
                if (!selecteddestination) throw "No destination"
                getNextPage(selecteddestination);
            } else {
                error.current.showerror(errormessage == "" ? "Required field" : errormessage);
                setWait(false);
            }
        } catch (e) {
            console.log(e);
            setWait(false);
        }
    }

    useEffect(() => {
        if (!state || !state?.destination) navigate("/")
        if (self === "self")
            fetchuserprofile();
        else if (state?.profile)
            setBasicInfo(state?.profile)


    }, []);

    useEffect(() => {
        if (state?.destination)
            getNextPage(state.destination)


    }, [basicInfo]);
    const handleChange = (newValue, inputid) => {
        if (newValue) {
            value[inputid] = newValue
            setValue(value);

        }
    };
    function createBinding(item, runonce) {
        var fn;
        if (item.bindingtarget)
            fn = new Function(`try{document.getElementsByName('${item.bindingtarget}')[0].value =${item.bindingscript}}catch(e){console.log(e);}`)
        else
            fn = new Function(`try{${item.bindingscript}}catch(e){console.log(e);}`)

        return fn;
    }

    function OtherInput(props) {
        var e = props.data;
        var item_property = e.item_property ? JSON.parse(e.item_property) : {};
        e.react_style = Object.assign({}, e.item_style ? services.convertstyle(JSON.parse(e.item_style)) : {}, item_property.style ? services.convertstyle(item_property.style) : {});

        var inputprops = Object.assign({}, (e.item_property ? { ...item_property, style: undefined } : {}), {
            id: (e.page_name || page.name || 'item' + e.page_id) + e.id,
            type: props.type,
            name: e.name || e.page_id,
            onClick: e.bindingscript ? createBinding(e) : undefined,
            onLoad: e.bindingscript ? createBinding(e) : undefined,
            defaultChecked: currentanswer[(e.name || e.page_id) + "checked"] == "1" ? true : (e.defaultvalue ? true : undefined),

            value: "other",
            onChange: (e) => {
                setHasother(e.target.checked)

            }

        });
        const [hasother, setHasother] = React.useState(false);
        const [inputValue, setinputValue] = React.useState("");
        const handleKeyDown = function (e) {
            e.target.style.height = 'inherit';
            e.target.style.height = `${e.target.scrollHeight}px`;
            // In case you have a limitation
            // e.target.style.height = `${Math.min(e.target.scrollHeight, limit)}px`;
        }
        return <>
            <div className="input-group border-0">
                {props.type == "checkbox" ? <MDBCheckbox {...inputprops} label={t(e.note)} /> : <MDBRadio {...inputprops} label={t(e.note)} />}
                <textarea className={"form-control " + (e.react_style.width == "100%" ? "ms-3" : "ms-2")}
                    name={e.name || e.page_id}
                    onChange={(event) => {
                        var other = document.getElementById((e.page_name || page.name || 'item' + e.page_id) + e.id);
                        if (!other?.checked) other.click();
                        (event.target.value ? setHasother(true) : setHasother(false));
                        setinputValue(event.target.value);
                    }}
                    onKeyDown={handleKeyDown}
                    {...(e.item_property ? JSON.parse(e.item_property) : {})}
                    id={(e.page_name || page.name || 'item' + e.page_id) + e.id}
                    defaultValue={currentanswer[e.name || e.page_id] ? currentanswer[e.name || e.page_id] : (e.defaultvalue ? e.defaultvalue : undefined)}
                ></textarea>
            </div>
        </>
    }

    function mapitem(e) {
        if (e.item_property) {
            e.property = JSON.parse(e.item_property);
            e.className = e.item_property.class || "";
            delete e.item_property.class;
        }

        e.react_style = e.item_style ? services.convertstyle(JSON.parse(e.item_style)) : {};
        return (<React.Fragment key={(e.page_name || page.name || 'item' + e.page_id) + e.id}>
            {(() => {
                switch (e.item_type) {
                    case "radio":
                    case "checkbox":
                        return (<div key={(e.page_name || page.name || 'item' + e.page_id) + e.id}
                            parentitemname={(e.property && e.property.parentitemname)}
                            parentitemid={(e.property && e.property.parentitemid)}
                            className={"input-group mb-2" + (e.property && e.property.parentitemname ? " ms-4" : "")} style={e.item_style ? e.react_style : {}}>
                            <div className="input-group border-0">
                                {e.item_type == "checkbox" ? <MDBCheckbox
                                    className={"form-check-input " + e.className}
                                    type={e.item_type}
                                    name={e.name || e.page_id}
                                    {...(e.property ? e.property : { value: "1" })}
                                    id={(e.page_name || page.name || 'item' + e.page_id) + e.id}
                                    onClick={e.bindingscript ? createBinding(e, true) : undefined}
                                    defaultChecked={e.defaultvalue ? true : undefined}
                                    label={t(e.note)}
                                    labelClass="text-wrap text-start"
                                /> :
                                    <MDBRadio
                                        className={"form-check-input " + e.className}
                                        type={e.item_type}
                                        name={e.name || e.page_id}
                                        {...(e.property ? e.property : { value: "1" })}
                                        id={(e.page_name || page.name || 'item' + e.page_id) + e.id}
                                        onClick={e.bindingscript ? createBinding(e, true) : undefined}
                                        defaultChecked={e.defaultvalue ? true : undefined}
                                        label={t(e.note)}
                                        labelClass="text-wrap text-start"
                                    />
                                }
                            </div></div>)
                    case "hidden":
                        return (<input
                            type={e.item_type}
                            name={e.name || e.page_id}
                            {...(e.property ? e.property : {})}
                            id={(e.page_name || page.name || 'item' + e.page_id) + e.id}
                            value={e.defaultvalue ? e.defaultvalue : undefined} />)
                    case "date": return (<div className="form-outline mb-3"
                        parentitemname={(e.property && e.property.parentitemname)}
                        parentitemid={(e.property && e.property.parentitemid)}
                        style={e.item_style ? e.react_style : {}}>
                        {dateAdapter !== "th" && <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enUS} >

                            <div><DesktopDatePicker
                                label={t(e.note)}
                                minDate={new Date(((new Date().getFullYear()) - 150).toString() + '-01-01')}

                                value={value["date-" + (e.name || e.page_id)] ? value["date-" + (e.name || e.page_id)] : e.defaultvalue}

                                onChange={(newValue) => handleChange(newValue, "date-" + (e.name || e.page_id))}
                                slotProps={{ textField: { sx: { width: "100%" }, variant: 'outlined', size: 'small', name: "date-" + (e.name || e.page_id), id: (e.page_name || page.name || 'item' + e.page_id) + e.id } }}
                                format="dd/MMM/yyyy"
                            /></div>

                        </LocalizationProvider>}
                        {dateAdapter == "th" && <LocalizationProvider dateAdapter={AdapterDateFnsBuddhist} adapterLocale={th} >

                            <div><DesktopDatePicker
                                label={t(e.note)}
                                minDate={new Date(((new Date().getFullYear()) - 150).toString() + '-01-01')}

                                value={value["date-" + (e.name || e.page_id)] ? value["date-" + (e.name || e.page_id)] : e.defaultvalue}

                                onChange={(newValue) => handleChange(newValue, "date-" + (e.name || e.page_id))}
                                slotProps={{ textField: { sx: { width: "100%" }, variant: 'outlined', size: 'small', name: "date-" + (e.name || e.page_id), id: (e.page_name || page.name || 'item' + e.page_id) + e.id } }}
                                format="dd/MMM/yyyy"
                            /></div>

                        </LocalizationProvider>}</div>)
                    case "otherradio":
                        return (<OtherInput type="radio" data={e} />)
                    case "othercheckbox":
                        return (<OtherInput type="checkbox" data={e} />)

                    default: return (<div className="form-outline mb-3 justify-content-center d-flex w-100"
                        parentitemname={(e.property && e.property.parentitemname)}
                        parentitemid={(e.property && e.property.parentitemid)}
                        style={e.item_style ? e.react_style : {}}><MDBInput
                            className={" " + e.className}
                            wrapperStyle={{ width: "22rem" }}
                            type={e.item_type}
                            label={t(e.note)}
                            name={e.name || e.page_id}
                            {...(e.item_property ? JSON.parse(e.item_property) : {})}
                            id={(e.page_name || page.name || 'item' + e.page_id) + e.id}
                            defaultValue={e.defaultvalue ? e.defaultvalue : undefined} /></div>)

                }
            })()}

        </React.Fragment >)
    }
    return (<>
        <Menu requirelogin="true" allowPrevious={true} title={!basicInfo.gender ? t("Symptom checker") : (t("Symptom checker title") + t(basicInfo.gender == "M" ? "Male" : "Female") + ' ' + t("Agelabel", { age: (basicInfo.age == 0 ? window.calculateAgeMonth(basicInfo.dob) : basicInfo.age) }) + ' ' + t(basicInfo.age == 0 ? "M" : "Y"))} PreviousCallback={getPreviousPage} />
        <div className="container">
            <section className='pt-4 p-md-4'>
                <ErrorDialog ref={error} />
                {!basicInfo.gender && <BasicinfoComponent callback={updateBasicinfo} />}
                {basicInfo.gender && page.note &&
                    <div className="mx-0 mx-sm-auto">
                        <form className="card" onSubmit={(e) => handleSubmit(e, page)}>
                            <div className="card-body">
                                <div className="text-center">
                                    {![2, 53, 25, 26, 27].includes(parseInt(id)) && <i className="fas fa-head-side-mask fa-4x mb-3 text-primary"></i>}
                                    {[53, 2].includes(parseInt(id)) && <i className="fas fa-syringe fa-4x mb-3 text-primary"></i>}
                                    {[25, 26, 27].includes(parseInt(id)) && <i className="fas fa-hand-holding-heart fa-4x mb-3 text-primary"></i>}
                                    <p>
                                        {self === "self" && t("Self assessment") + " " + t("Topic") + ": " + t(page.topic.note)}
                                        {self !== "self" && t("Not-self assessment") + " " + t("Topic") + ": " + t(page.topic.note)}
                                    </p>
                                    <h5>
                                        {t(page.note)}
                                    </h5>
                                </div>

                                <hr />

                                <div className="px-4 row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 justify-content-center" action="" autoComplete="off">
                                    <div className="col">
                                        <p className="text-center"><strong>{t("Answer:")}</strong></p>

                                        {page.items?.length
                                            ? (
                                                <>
                                                    {page.items.filter(e => e.optional !== "Y" && e.optional !== "A").map(mapitem)}
                                                    {page.items.filter(e => e.optional === "Y").length > 0 &&
                                                        <><MDBBtn outline onClick={handleExpandClick}>
                                                            {!expanded ? <MDBIcon fas icon="angle-down" className='me-2' /> : <MDBIcon fas icon="angle-up" className='me-2' />}
                                                            {t("optional answer")}
                                                        </MDBBtn>
                                                            <MDBCollapse open={expanded}>
                                                                {page.items.filter(e => e.optional == "Y" || e.optional == "A").map(mapitem)}
                                                            </MDBCollapse>
                                                        </>
                                                    }
                                                </>

                                            ) : ''
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="card-footer text-end">
                                <MDBBtn type="submit">{t("Next")}</MDBBtn>
                            </div>
                        </form>
                    </div>
                }
            </section>
        </div>

    </>
    );
}