import {
    MDBBtn,
    MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter, MDBIcon, MDBTextArea, MDBRadio,
    MDBRating, MDBRatingElement
}
    from 'mdb-react-ui-kit';
import React, { forwardRef, useImperativeHandle, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import services from '../../helper/services';

const FeedbackModal1 = forwardRef((props, ref) => {
    const { t, i18n } = useTranslation('translations');
    const [feedbackModal, setFeedbackModal] = useState(false);
    const dispatch = useDispatch();
    const [selectValue, setSelectValue] = useState(0);

    const handleSubmit = (e) => {
        e.preventDefault();

        const items = { score: selectValue };
        for (const pair of (new FormData(e.target)).entries()) {
            items[pair[0]] = pair[1];
        }

        if (props.resultId) {
            items["resultId"] = props.resultId
        }
        if (!(items.score == 0 && items.comment == ""))
            services.postFeedback(items)


        setFeedbackModal(false);
        window.shownfeedback = false;
        if (props.onClose) props.onClose();
    }
    const toggleShow = (e) => window.shownfeedback = false | e.preventDefault() | setFeedbackModal(!feedbackModal);

    useImperativeHandle(ref, () => ({
        showFeedbackModal: () => {

                //window.shownfeedback = true;
                setFeedbackModal(true);
               
            
        }
    }))

    function changeLanguageHandler(lang) {
        i18n.changeLanguage(lang)
        dispatch({ type: "changeLanguage", payload: lang })

    }


    useEffect(() => {
        if (props.show) {

            setTimeout(() => {
                if (!window.shownfeedback) {

                    window.shownfeedback = true;
                    setFeedbackModal(true)
                }
            }, props.delay ?? 500);
        }

    }, []);
    return (<MDBModal open={feedbackModal} tabIndex='-1' staticBackdrop key={feedbackModal}>
        <MDBModalDialog className='' centered>
            <MDBModalContent>
                <form onSubmit={(e) => handleSubmit(e)}>
                    <MDBModalHeader className='justify-content-center'>
                        <MDBModalTitle dangerouslySetInnerHTML={
                            { __html: t("Feedback", { interpolation: { escapeValue: false } }) }
                        }></MDBModalTitle>
                        <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody className='text-start'>
                        <p className="text-center" dangerouslySetInnerHTML={
                            { __html: t("Feedback_desc", { interpolation: { escapeValue: false } }) }
                        }></p>

                        <div className="d-flex justify-content-center my-4">
                            <MDBRating dynamic onChange={(value) => setSelectValue(value)} >
                                <MDBRatingElement size='lg' itemId={1} icon='angry' color='#673ab7' />
                                <MDBRatingElement size='lg' itemId={2} icon='frown' color='#3f51b5' />
                                <MDBRatingElement size='lg' itemId={3} icon='meh' color='#2196f3' />
                                <MDBRatingElement size='lg' itemId={4} icon='smile' color='#03a9f4' />
                                <MDBRatingElement size='lg' itemId={5} icon='grin-stars' color='#00bcd4' />
                            </MDBRating>
                        </div>
                        {props.resultId &&
                            <><p className="text-center" dangerouslySetInnerHTML={
                                { __html: t("Feedback_desc_result", { interpolation: { escapeValue: false } }) }
                            }></p>


                                <div className="d-flex mt-4 mb-2">

                                    <MDBRadio name='resultFeedback' label={t("resultfeedback1")} id="resultfeedback1" value="resultfeedback1" />
                                </div>
                                <div className="d-flex mb-2">
                                    <MDBRadio name='resultFeedback' label={t("resultfeedback2")} id="resultfeedback2" value="resultfeedback2" />
                                </div>
                                <div className="d-flex mb-4">
                                    <MDBRadio name='resultFeedback' label={t("resultfeedback3")} id="resultfeedback3" value="resultfeedback3" />

                                </div>

                            </>
                        }

                        <p className="text-center">
                            <strong>{t("Feedback_comment")}</strong>
                        </p>
                        <MDBTextArea label={t("Feedback_comment_label")} rows="10" name="comment" /></MDBModalBody>

                    <MDBModalFooter className='justify-content-between'>
                        {false && <MDBDropdown group className='shadow-0' onClick={(e) => e.preventDefault()}>
                            <MDBDropdownToggle color='link' >{i18n.language.toLowerCase().startsWith("th") ? "TH" : "EN"}</MDBDropdownToggle>
                            <MDBDropdownMenu responsive='end'>
                                <MDBDropdownItem link onClick={() => changeLanguageHandler("en")}>EN</MDBDropdownItem>
                                <MDBDropdownItem link onClick={() => changeLanguageHandler("th")}>TH</MDBDropdownItem>

                            </MDBDropdownMenu>
                        </MDBDropdown>}
                        <MDBBtn color='secondary' onClick={toggleShow}>
                            {t(`Cancel`)}
                        </MDBBtn>

                        <MDBBtn >
                            {t(`Submit`)}
                        </MDBBtn>

                    </MDBModalFooter>
                </form>
            </MDBModalContent>
        </MDBModalDialog>
    </MDBModal>)
});

export default FeedbackModal1;