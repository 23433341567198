import React, { forwardRef, useImperativeHandle, useRef, useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
    MDBCollapse, MDBListGroup, MDBListGroupItem, MDBIcon, MDBBtn, MDBBadge

} from 'mdb-react-ui-kit';
import ErrorDialog from './errordialog';
import services from "../../helper/services";
import moment from 'moment';

const component = forwardRef((props, ref) => {
    const navigate = useNavigate();
    const error = useRef();
    const [urgentcolor] = useState(["", "success", "warning", "danger"]);
    const { t } = useTranslation('translations');
    const el = props.el;
    const main = props.main;

    const [expanded, setExpanded] = useState(true);
    const handleExpandClick = (e) => {
        e.preventDefault();
        setExpanded(!expanded);
    };
    useEffect(() => {
        if (expanded)
            fixheight();
    }, [expanded])
    function fixheight() {
        setTimeout(() => {
            if (document.getElementsByClassName("collapsedfix"))
                for (var el of document.getElementsByClassName("collapsedfix")) el.style.height = null;
        }, 300);

    }
    const handleNavigateClick = (e, o) => {
        navigate((o.onepage !== "Y" ? "/checkupresult/" : "/result/") + o.result_id, { state: { hasPrevious: "Y" } })
    }



    return (
        <React.Fragment>
            <h6 className='bg-light p-2 border-top border-bottom'
                onClick={handleExpandClick}
            >{t("resultdatetime", {
                val: new Date(el), formatParams: {
                    val: {
                        day: 'numeric', month: 'short', year: 'numeric'
                    },
                }
            })}   <i className={"fas ms-2 fa-angle-" + (expanded ? "up" : "down")} style={{ fontSize: '20px', color: '#2C2F78' }}></i></h6>
            <MDBCollapse open={expanded} className="px-0 px-md-2 collapsedfix">
                <MDBListGroup light className='mb-4'>
                    {main[el].map((o, i) =>
                        <MDBListGroupItem key={"item-" + el + "child" + i} role='button' className='d-flex justify-content-between align-items-center' onClick={(e) => handleNavigateClick(e, o)} >
                            <div className='d-flex align-items-center'>
                                <MDBIcon fas icon={(o.self_exam === "Y" ? "user-alt" : "users") + " fa-2x fa-fw mx-3 text-primary"} />
                                <div className='ms-3'>
                                    <p className="fw-bold mb-1 align-items-baseline"><span className=' '>{t("resultdatetime", {
                                        val: new Date(o.createtime), formatParams: {
                                            val: {
                                                hour: '2-digit',
                                                minute: '2-digit',
                                            },
                                        }
                                    })}</span><MDBBadge className='text-dark ms-2' color={o.self_exam === "Y" ? "light" : "info"} light>{t(o.self_exam === "Y" ? "Self assessment" : "Not-self assessment")}</MDBBadge></p>
                                    <p className='text-muted mb-0'>{(t("Topic Title", { topic: t(o.topic_note) }) + t(o.gender == "M" ? "Male" : "Female") + ' ' + t("Agelabel", { age: (o.age == 0 ? window.calculateAgeMonth(o.dob) : o.age) }) + ' ' + t(o.age == 0 ? "M" : "Y"))}</p>

                                </div>
                            </div>
                            <MDBBtn size='sm' style={{ whiteSpace: "nowrap" }} className="d-none d-xl-block" rounded color='link' onClick={(e) => handleNavigateClick(e, o)}>
                                {t("View")}
                            </MDBBtn>

                        </MDBListGroupItem>)}
                </MDBListGroup>
            </MDBCollapse>
        </React.Fragment>
    );
});





export default component;