import React, { useState, useRef, useEffect } from "react";
import Menu from './sub/appbar';

import { useTranslation } from 'react-i18next';

import AdminHistorylist from './sub/adminhistorylist'
import Adminpersonalhealth from './sub/adminpersonalhealth'
import { socket } from "../helper/socket";
import { MDBBadge } from 'mdb-react-ui-kit';
import { useNavigate } from 'react-router-dom';
import services from '../helper/services';
import ErrorDialog from './sub/errordialog';

export default function Main() {
    const navigate = useNavigate();
    const [expanded, setExpanded] = useState(false);
    const { t } = useTranslation('translations');
    const [otherprofile, setOtherprofile] = useState({});
    const [userprofile, setUserprofile] = useState({});
    const [notification, setNotification] = useState({});
    const [notiMessage, setNotiMessage] = useState({});
    const error = useRef();
    const checkuplist = useRef();
    const symptomlist = useRef();
    const healthlist = useRef();

    async function fetchuserprofile() {

        try {
            var res = await services.fetchuserprofile()
            setUserprofile(res)
            if (!(res.role == "admin" || res.role == "staff")) {
                navigate("/")
            }
        } catch (e) {
            error.current.showerror(e);
        }

    }

    const [s_showDiv, setS_showDiv] = useState(0);

    const [ns_showDiv, setNs_showDiv] = useState(0);

    const handleRadioChange = (e) => {

        setS_showDiv(e.target.value)
    };
    const handleclick = (target) => {
        let current;
        switch (target) {
            case "P":
                current = healthlist;
                break;
            case "S":
                current = symptomlist;
                break;
            case "C":
                current = checkuplist;
                break;

        }

        if (current.current) {
            if (current.current.updateList)
                current.current.updateList(notification[target]);
        }
        setNotification({ ...notification, [target]: [] });
    }
    function newmessage(msg) {

        let newnoti = notification[msg.type];
        if (!newnoti) newnoti = [];
        if (!newnoti.includes(msg.id)) {
            newnoti.push(msg.id);
        }
        setNotification({ ...notification, [msg.type]: newnoti });

    }

    function deletemessage(msg) {

        let newnoti = notification[msg.type];
        if (!newnoti) return;
        var index = newnoti.indexOf(msg.id);
        if (index !== -1) {
            newnoti.splice(index, 1);
        }
        setNotification({ ...notification, [msg.type]: newnoti });
    }

    useEffect(() => {
        if (notiMessage.flag) {
            if (notiMessage.flag == "delete") {
                deletemessage(notiMessage.msg);
            }
            if (notiMessage.flag == "new") {
                newmessage(notiMessage.msg);
            }
            setNotiMessage({});
        }
    }, [notiMessage]);
    useEffect(() => {

        socket.on('new', (msg) => {
            setNotiMessage({ flag: "new", msg: msg })
        });

        socket.on('delete', (msg) => { setNotiMessage({ flag: "delete", msg: msg }) });

        fetchuserprofile();
    }, []);
    return (<>
        <Menu requirelogin="true" title={t("Admin History")} />
        <div className="container-fluid pt-5">
            <section className='py-4'>
                <div className="container-fluid text-center pt-2 pb-2">
                    <ErrorDialog ref={error} />
                    <div className="justify-content-center">
                        <div className="bg-light shadow container-fluid">
                            {/* <div className="row">
                                <div className="col my-3">
                                    <h5 className="border-bottom border-1" style={{ color: '#2C2F78' }}>
                                        <i className="fas fa-file-medical m-2" style={{ fontSize: '20px', color: '#2C2F78' }}></i> {t("History")}</h5>
                                </div>
                            </div>
                            */}
                            <div className="row py-3">
                                <div className="col mb-2">
                                <label className='position-relative d-inline-block'>
                                        <div className={`btn btn-lg btn${s_showDiv === "s_chkup_topic" ? "-primary" : "-secondary"}`}  style={{  borderRadius: '14px', border: 'none', fontSize: '16px', fontWeight: 'bold' }}>

                                            <input type="radio" className="form-check-input d-none" id="s_chkup_topic" name="s_assessment_topic" value="s_chkup_topic"
                                                checked={s_showDiv == "s_chkup_topic"}
                                                onChange={handleRadioChange} onClick={() => handleclick("C")}
                                            />
                                            {t("Check up: ")}
                                        </div>
                                        {notification.C && notification.C?.length > 0 && <MDBBadge pill className='position-absolute translate-middle' color='danger' >
                                                {notification.C?.length ?? ""}
                                            </MDBBadge>}
                                    </label>
                                </div>
                                <div className="col mb-2">
                                <label className='position-relative d-inline-block'>
                                        <div className={`btn btn-lg btn${s_showDiv === "s_symptom_topic" ? "-primary" : "-secondary"}`}  style={{  borderRadius: '14px', border: 'none', fontSize: '16px', fontWeight: 'bold' }}>

                                            <input type="radio" className="form-check-input d-none" id="s_symptom_topic" name="s_assessment_topic" value="s_symptom_topic"
                                                checked={s_showDiv == "s_symptom_topic"}
                                                onChange={handleRadioChange} onClick={() => handleclick("S")} />
                                            {t("Symptom: ")}
                                        </div>
                                        {notification.S && notification.S?.length > 0 && <MDBBadge pill className='position-absolute translate-middle' color='danger'>
                                                {notification.S?.length ?? ""}
                                            </MDBBadge>}
                                    </label>
                                </div>
                                <div className="col mb-2">
                                    <label className='position-relative d-inline-block'>
                                        <div className={`btn btn-lg btn${s_showDiv === "s_personalhealth_topic" ? "-primary" : "-secondary"}`}  style={{  borderRadius: '14px', border: 'none', fontSize: '16px', fontWeight: 'bold' }}>

                                            <input type="radio" className="form-check-input d-none" id="s_personalhealth_topic" name="s_assessment_topic" value="s_personalhealth_topic"
                                                checked={s_showDiv == "s_personalhealth_topic"}
                                                onChange={handleRadioChange} onClick={() => handleclick("P")} />
                                            {t("Personal Health Abnormal: ")}
                                        </div>
                                        {notification.P && notification.P?.length > 0 && <MDBBadge pill className='position-absolute translate-middle' color='danger'>
                                                {notification.P?.length ?? "0"}
                                            </MDBBadge>}
                                    </label>
                                </div>
                            </div>
                            <div className="row p-4">

                                {s_showDiv === "s_chkup_topic" && <AdminHistorylist ref={checkuplist} checkup="Y" />}
                                {s_showDiv === "s_symptom_topic" && <AdminHistorylist ref={symptomlist} checkup="N" />}
                                {s_showDiv === "s_personalhealth_topic" && <Adminpersonalhealth ref={healthlist} />}
                            </div>
                        </div>

                    </div>

                </div>
            </section>
        </div >

    </>
    );
}