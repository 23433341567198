import {
    MDBListGroup, MDBListGroupItem, MDBBadge,
    MDBInput
    , MDBInputGroup
    , MDBBtn
    , MDBIcon
    , MDBTextArea
    , MDBTimepicker
}
    from 'mdb-react-ui-kit';
import React, { forwardRef, useImperativeHandle, useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';

import { useSelector } from "react-redux";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AdapterDateFnsBuddhist from '../../helper/date-fns-be';
import { th, enUS } from 'date-fns/locale';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Dropdown from "./dropdown";
import services from "../../helper/services";
import { MDBAutocomplete } from 'mdb-react-ui-kit';

const EntryMedicationForm = forwardRef((props, ref) => {

    const { t, i18n } = useTranslation('translations');
    const dateAdapter = useSelector(state => state.lang);
    const [listMed, setListMed] = useState(props.medList ?? []);
    const _getResult = (exclude) => {
        const listItem = [];


        return listItem;
    }
    const feq = useRef();
    const medication = useRef();

    const [medicationData, setMedicationData] = useState([]);
    const [medicationValue, setMedicationValue] = useState({});

    const [medicationFeqData, setMedicationFeqData] = useState([]);
    const [medicationFeqValue, setMedicationFeqValue] = useState({});
    const [isFeqLoading, setIsFeqLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false);

    const onMedicationSearch = async (query) => {

       setIsLoading(true);
        const data = await services.getMedication(t,'medication', query, '');

        await setIsLoading(false);

        setMedicationData(data);
    };

    const onFeqSearch = async (query) => {

        setIsFeqLoading(true);
         const data = await services.getMedication(t,'feq', query, '');
 
         await setIsFeqLoading(false);
 
         setMedicationFeqData(data);
     };

    function additem(e) {
        e.preventDefault();
        const a =medicationValue;
        // let item = { hn: document.getElementById("add_hn").value, alias: document.getElementById("add_hn_alias").value }
        // if ((!item.hn) ||
        //     (!item.alias)) {
        //     props?.errorref?.current?.showerror("Invalid info");
        //     return;
        // }
        // if (listHN.some(el => el.hn == item.hn && el.alias == item.alias && el.department == department.current.getResult().value))
        //     props?.errorref?.current?.showerror("already added");
        // const newlistHN = [...listHN];
        // newlistHN.push({ hn: item.hn, alias: item.alias, department: department.current.getResult().value });
        // setListHN(newlistHN);


    }
    function changeitem(e) {
        e.preventDefault();
        let target = e.target;
        const newlistMed = [...listMed];
        newlistMed[target.parentNode.parentNode.getAttribute("data-item")][target.getAttribute("data-field")] = target.value;
        setListMed(newlistMed);
    }
    function removeitem(e, item) {
        e.preventDefault();
        let newlistMed = [...listMed];
        newlistMed = newlistMed.filter(el => el != item)
        setListMed(newlistMed);
    }


    useImperativeHandle(ref, () => ({
        getResult: () => {

            return _getResult();
        },
        checkvalidField: () => {
            return true;
        }
    }));


    return (<>
        <div className='mt-2'>
            <p className="pb-lg-2" style={{ color: '#3B4054' }}>{t('add currentmed')}</p>
        </div>
        <div className=' mb-3'>
            <MDBInputGroup className='mb-3 custom-input-group'>
                <MDBAutocomplete
                    data={medicationData}
                    isLoading={isLoading}
                    label={t("medication")}
                    listHeight="320px"
                    noResults={t("noresult")}
                    displayValue={(row) => t(row.text)}
                    itemContent={(result) => (
                        <span className='autocomplete-custom-item-content'>
                          <span className='autocomplete-custom-item-title'>{t(result.text)}</span>
                          <span className='autocomplete-custom-item-subtitle'>{t(result.detail??"")}</span>
                        </span>
                      )}
                    onSearch={onMedicationSearch}
                    onSelect={(value) => 
                        setMedicationValue(value)}
                />
                 <MDBAutocomplete
                    data={medicationFeqData}
                    isLoading={isFeqLoading}
                    label={t("medicationfeq")}
                    noResults={t("noresult")}
                    displayValue={(row) => t(row.text)}
                    itemContent={(result) => (
                        <span className='autocomplete-custom-item-content'>
                          <span className='autocomplete-custom-item-title'>{t(result.text)}</span>
                        </span>
                      )}
                    onSearch={onFeqSearch}
                    onSelect={(value) => 
                        setMedicationFeqValue(value)}
                />
                {/* <MDBInput label={t('Enter feq')} id='add_hn' maxLength={100} />

                <MDBInput label={t('Enter dosage')} id='add_hn_alias' maxLength={100} /> */}
                <MDBBtn type="button" onClick={additem} ><MDBIcon fas icon="plus" /></MDBBtn>
            </MDBInputGroup>
            <MDBInputGroup className='custom-input-group'>

            </MDBInputGroup>

        </div>


        {listMed.length > 0 && <h6 className='bg-light p-2 border-top border-bottom'>{t("listmed")}</h6>}



    </>);
});


export default EntryMedicationForm;