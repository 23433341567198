import React, { useState, useRef, useEffect } from "react";
import Menu from './sub/appbar';
import { MDBCollapse } from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';
import Checkuplist from './sub/checkuplist'
import Historylist from './sub/historylist'
import BasicinfoComponent from "./sub/basicinfo";
import { useNavigate } from 'react-router-dom';
import services from '../helper/services';
import ErrorDialog from './sub/errordialog';

export default function Main() {
    const navigate = useNavigate();
    const [expanded, setExpanded] = useState(false);
    const { t } = useTranslation('translations');
    const [otherprofile, setOtherprofile] = useState({});
    const [userprofile, setUserprofile] = useState({});




    const error = useRef();

    async function fetchuserprofile() {

        try {
            var res = await services.fetchuserprofile()
            setUserprofile(res)
        } catch (e) {
            error.current.showerror(e);
        }

    }

    const [s_showDiv, setS_showDiv] = useState((process.env.REACT_APP_IMPLEMENT == "HOSPITAL" ?0:true));

    const [ns_showDiv, setNs_showDiv] = useState(0);

    const handleRadioChange = (e) => {

        if (e.target.value == "ns_chkup_topic" || e.target.value == "ns_symptom_topic") {
            setS_showDiv(0)
            setNs_showDiv(e.target.value === 'ns_chkup_topic');
        }
        else {
            setNs_showDiv(0);
            setS_showDiv(e.target.value === 's_chkup_topic');
        }
    };

    useEffect(() => {
        fetchuserprofile();
    }, []);
    return (<>
        <Menu requirelogin="true" title={t("History")} allowPrevious="Y" PreviousCallback={() => navigate("/")} />
        <div className="container">
            <section className='p-md-4 py-4'>
                <div className="text-center pt-2 pb-2">
                    <ErrorDialog ref={error} />
                    <div className="justify-content-center">
                        <div className="bg-light shadow">
                            {/* <div className="row">
                                <div className="col my-3">
                                    <h5 className="border-bottom border-1" style={{ color: '#2C2F78' }}>
                                        <i className="fas fa-file-medical m-2" style={{ fontSize: '20px', color: '#2C2F78' }}></i> {t("History")}</h5>
                                </div>
                            </div> */}
                            <div className="container-fluid " >
                                <div className="row py-3">
                                    <div className="col mb-2">
                                        <label className="">
                                            <div className={`btn btn-lg btn${s_showDiv === true ? "-primary" : "-secondary"}`}  style={{ borderRadius: '14px', border: 'none', fontSize: '16px', fontWeight: 'bold' }}>

                                                <input type="radio" className="form-check-input d-none" id="s_chkup_topic" name="s_assessment_topic" value="s_chkup_topic"
                                                    checked={s_showDiv}
                                                    onChange={handleRadioChange}
                                                />
                                                {t("Check up: ")}

                                            </div>
                                        </label>
                                    </div>
                                    {process.env.REACT_APP_IMPLEMENT == "HOSPITAL" &&  <div className="col mb-2">
                                        <label className="">
                                            <div className={`btn btn-lg btn${s_showDiv === false ?"-primary" : "-secondary"}`}  style={{ borderRadius: '14px', border: 'none', fontSize: '16px', fontWeight: 'bold' }}>

                                                <input type="radio" className="form-check-input d-none" id="s_symptom_topic" name="s_assessment_topic" value="s_symptom_topic"
                                                    checked={s_showDiv === false}
                                                    onChange={handleRadioChange} />
                                                {t("Symptom: ")}

                                            </div>
                                        </label>
                                    </div>}
                                </div>
                            </div>
                            <div className="row p-4">

                                {s_showDiv === true && <Historylist checkup="Y" />}
                                {s_showDiv === false && <Historylist checkup="N" />}
                            </div>
                        </div>

                    </div>

                </div>
            </section>
        </div >

    </>
    );
}