//External imports
import React, { forwardRef, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import {
    MDBIcon,
    MDBBtn

} from 'mdb-react-ui-kit';
import { getAuth, signInWithPopup, OAuthProvider } from "firebase/auth";

const AppleAuth = forwardRef((props, ref) => {
    const { t, i18n } = useTranslation('translations');

    const auth = getAuth();
    const provider = new OAuthProvider('apple.com');
    function handleClick() {
        signInWithPopup(auth, provider)
            .then((result) => {
                // The signed-in user info.
                const user = result.user;

                // Apple credential
                const credential = OAuthProvider.credentialFromResult(result);
                const accessToken = credential.accessToken;
                const idToken = credential.idToken;
                if (props.onSuccess)
                    props.onSuccess({ credential: idToken }, user)
                // IdP data available using getAdditionalUserInfo(result)
                // ...
            })
            .catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The credential that was used.
                const credential = OAuthProvider.credentialFromError(error);

                // ...
            });
    }

    return (
        <MDBBtn color='dark' size='lg' outline style={{ maxHeight: "40px", textTransform: "none", minWidth: "200px" }} onClick={handleClick}>
            <MDBIcon className='me-2' fab icon='apple' size='lg' />{props.label}
        </MDBBtn>
    )
})

export default AppleAuth