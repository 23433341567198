import React, { forwardRef, useImperativeHandle, useRef, useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
    MDBCollapse, MDBListGroup, MDBListGroupItem, MDBIcon, MDBBtn

} from 'mdb-react-ui-kit';
import ErrorDialog from './errordialog';
import services from "../../helper/services";
import { useSelector } from "react-redux";

const component = forwardRef((props, ref) => {
    const navigate = useNavigate();
    const error = useRef();
    const [urgentcolor] = useState(["", "success", "warning", "danger"]);
    const { t } = useTranslation('translations');
    const el = props.el;
    const group_time = (obj) => {
        var main_item = services.groupBy(obj, "record_datetime");
        const keys = Object.keys(main_item).sort(function (a, b) { return b.toString().localeCompare(a.toString()) })
        const items = [];
        for (var key of keys) {
            const initialValue = {};
            let item = main_item[key].reduce((obj, item) => {
                return {
                    ...obj,
                    [item["name"]]: { value: item.value, flag: item.flag },
                    parentid: item.parentid
                };
            }, initialValue);
            items.push({ ...item, record_datetime: key });
        }
        return items;
    }
    const [mainel, setMainel] = useState(group_time(props.main[el]));
    const hn = props.hn
    const dateAdapter = useSelector(state => state.lang);
    const [expanded, setExpanded] = useState(true);
    const handleExpandClick = (e) => {
        e.preventDefault();
        setExpanded(!expanded);
    };
    useEffect(() => {
        if (expanded)
            fixheight();
    }, [expanded])
    function fixheight() {
        setTimeout(() => {
            if (document.getElementsByClassName("collapsedfix"))
                for (var el of document.getElementsByClassName("collapsedfix")) el.style.height = null;
        }, 300);

    }
    const handleDelete = async (e, o) => {
        e.preventDefault();
        await services.deletepersonalhealth({ hn: hn, parentid: o.parentid })
        setMainel(mainel.filter(e => e.parentid != o.parentid));

    };

    return (
        <React.Fragment>
            <tr className="table-secondary">{mainel.length > 0 && < th colSpan="7" onClick={handleExpandClick}>{t("resultdatetime", {
                val: new Date(el), formatParams: {

                    val: {
                        lng: (dateAdapter == "th" ? "th" : "en-GB"),
                        day: 'numeric', month: 'long', year: 'numeric'
                    },
                }
            })}
            </th>}</tr>


            {
                mainel.map((o, i) =>
                    <tr key={"item-" + el + "child" + i}  >
                        <td className="text-center px-md-2 ">
                            <MDBBtn color='link' floating onClick={(e) => handleDelete(e, o)}  >
                                <MDBIcon fas icon="trash-alt" color="danger" size="lg" />
                            </MDBBtn>
                        </td>
                        <td className="text-center px-md-4">{t("resultdatetime", {
                            val: new Date(o.record_datetime), formatParams: {
                                val: {
                                    lng: (dateAdapter == "th" ? "th" : "en-GB"),
                                    hour: '2-digit',
                                    minute: '2-digit',
                                },
                            }
                        })}</td>
                        <td className={"text-end px-md-4 " + (o.blood_sugar?.flag ? "text-danger fw-bold" : "")}>
                            {o.blood_sugar.value}
                        </td>
                        <td className={"text-end px-md-4 " + (o.sbp?.flag ? "text-danger fw-bold" : "")}>
                            {o.sbp.value}
                        </td>
                        <td className={"text-end px-md-4 " + (o.dbp?.flag ? "text-danger fw-bold" : "")}>
                            {o.dbp.value}
                        </td>
                        <td className={"text-end px-md-4 " + (o.pulse?.flag ? "text-danger fw-bold" : "")}>
                            {o.pulse.value}
                        </td>
                        <td className=" px-lg-4 ">
                            <span className="overflow-auto d-block" style={{ height: "25px" }}>{o.comment.value}</span>
                        </td>
                        {/* <div className='ms-3'>
                            <p className='fw-bold mb-1'>{t("resultdatetime", {
                                val: new Date(o.record_datetime), formatParams: {
                                    val: {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    },
                                }
                            })}</p>
                            <p className='text-muted mb-0'>{t("Blood sugar: ") + o.blood_sugar + " " + t("BP: ") + o.sbp + "/" + o.dbp + " " + t("Pulse: ") + o.pulse}</p>
                            {o.comment != "" && <p className='text-muted mb-0'>({o.comment})</p>}
                        </div>
 */}
                        {/* <MDBBtn size='sm' rounded color='link' onClick={(e) => handleNavigateClick(e, o)}>
                                {t("View")}
                            </MDBBtn> */}

                    </tr>)
            }




        </React.Fragment >
    );
});





export default component;