import {
    MDBBtn,
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBRow,
    MDBCol,
    MDBIcon,
    MDBInput,
    MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
}
    from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom'
import React, { useCallback, useState, useRef, useEffect } from "react";
import services from '../helper/services';
import { useSelector } from "react-redux";

export default function Main() {
    const { t, i18n } = useTranslation('translations');


    function changeLanguageHandler(lang) {
        i18n.changeLanguage(lang)
        window.changeLanguage?.postMessage(lang.startsWith("th") ? "th" : "en");

    }

    const toggleClose = () => {
        window.Close?.postMessage("Close");
    }
    const [searchParams, setSearchParams] = useSearchParams();
    return (
        <>
            <div className="card">
                {searchParams.get("modal").toLowerCase() != "privacy" &&
                    <>
                       {/*  <div className="card-header">{t(`Terms of Service`)}</div> */}
                        <div className='card-body' dangerouslySetInnerHTML={
                            { __html: t("TOS" + (process.env.REACT_APP_HOSPITAL ?? ""), { interpolation: { escapeValue: false } }) }
                        }></div>
                    </>}



                {searchParams.get("modal").toLowerCase() == "privacy" &&
                    <>
                       {/*  <div className="card-header">{t(`Privacy policy`)}</div> */}
                        <div className='card-body' dangerouslySetInnerHTML={
                            { __html: t("Privacy" + (process.env.REACT_APP_HOSPITAL ?? ""), { interpolation: { escapeValue: false } }) }
                        }></div>
                    </>}
                <div className="card-footer ">
                    <div className='row justify-content-between'>
                        <div className='col'>
                            <MDBDropdown group className='shadow-0'>
                                <MDBDropdownToggle color='link' >{i18n.language.toLowerCase().startsWith("th") ? "TH" : "EN"}</MDBDropdownToggle>
                                <MDBDropdownMenu responsive='end'>
                                    <MDBDropdownItem link onClick={() => changeLanguageHandler("en")}>EN</MDBDropdownItem>
                                    <MDBDropdownItem link onClick={() => changeLanguageHandler("th")}>TH</MDBDropdownItem>

                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </div>
                        <div className='col text-end'>
                            <MDBBtn color='secondary' onClick={() => toggleClose()}>
                                {t(`OK`)}
                            </MDBBtn>
                        </div>
                    </div>
                </div>
            </div>

        </>)
}