import React, { useRef } from 'react';
import Menu from './sub/appbar';
import ErrorDialog from './sub/errordialog';
import Complete from './sub/complete';
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardHeader,
    MDBCardFooter,
    MDBContainer,
    MDBBtn, MDBInput
} from 'mdb-react-ui-kit';
import { useCallback, useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";


export default function Verify() {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation('translations');
    const [complete, setcomplete] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const error = useRef();


    const confirmepassword = useCallback(
        (e) => {

            e.preventDefault();
            const items = { code: searchParams.get("token") };
            for (const pair of (new FormData(e.target)).entries()) {

                items[pair[0]] = pair[1];
            }
            if (!items.token) {
                error.current.showerror("Invalid token")
                return;
            }
            if (!items.password || items.password.length < 6) {
                error.current.showerror("Invalid password")
                return;
            }
            if (!executeRecaptcha) {
                //console.log("Execute recaptcha not yet available");
                return;
            }



            executeRecaptcha("enquiryFormSubmit").then((gReCaptchaToken) => {
                //console.log(gReCaptchaToken, "response Google reCaptcha server");
                items.gRecaptchaToken = gReCaptchaToken;
                submitconfirmepassword(items);
            });
        },
        [executeRecaptcha]
    );

    const submitconfirmepassword = (items) => {
        fetch(process.env.REACT_APP_DOMAIN + "apismd/smd_c/user/newpassword", {
            method: "POST",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(items),
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.success) {
                    setcomplete(true);
                } else {
                    error.current.showerror(res.error.map(e => e.text || e.message || e).join(' '))
                }

            });
    };
    return (
        <><Menu />    <MDBContainer className='mt-2'>
            <ErrorDialog ref={error} />

            <div className='p-4 d-flex justify-content-center w-100'>
                {!complete ? <MDBCard alignment='center' className='col-md-6' >
                    <MDBCardBody>
                        <form onSubmit={confirmepassword}>
                            <MDBCardTitle>{t("Change Password")}</MDBCardTitle>

                            <MDBInput label={t("Enter new password")} id='password' name='password' type='password' className='my-2' />
                            <input type="hidden" value={searchParams?.get("token") ?? ""} name='token' />
                            <MDBBtn>{t("Confirm")}</MDBBtn>
                        </form>
                    </MDBCardBody>
                </MDBCard> : <MDBContainer fluid className='col-md-6'><Complete callback={() => navigate("/")} title="complete verify" desc="complete verify desc" btn="login" /></MDBContainer>}
            </div>
        </MDBContainer>
        </>
    );
}