import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import {
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane,
    MDBRow,
    MDBCol,
    MDBIcon,
    MDBInputGroup,
    MDBInput,
    MDBRadio, MDBBtnGroup, MDBBtn, MDBDatepicker

} from 'mdb-react-ui-kit';
import ErrorDialog from './errordialog';

import { useSelector } from "react-redux";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AdapterDateFnsBuddhist from '../../helper/date-fns-be';
import { th, enUS } from 'date-fns/locale';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {TextField} from '@mui/material'

const BasicInfo = forwardRef((props, ref) => {

    const error = useRef();

    const { t, i18n } = useTranslation('translations');
    const [userprofile, setUserprofile] = useState({ dob: null, dobstring: null });
    const [value, setValue] = useState(userprofile.dob);
    const dateAdapter = useSelector(state => state.lang);
    const handleSubmit = (e) => {

        e.preventDefault();
        if (document.getElementById("collapaseexpandcontainer"))
            document.getElementById("collapaseexpandcontainer").style.height = null;

        const items = {};
        for (const pair of (new FormData(e.target)).entries()) {

            items[pair[0]] = pair[1];
        }
        if(items["gender"+ props.name]){
            items.gender =items["gender"+ props.name]
        }

        if (!items.gender) {
            error.current.showerror("No gender")
            return;
        }

        if (!items.dob) {
            error.current.showerror("No dob")
            return;
        }
        else {
            items.dob = window.parseDate(items.dob, dateAdapter)
            items.dobstring = items.dob.toLocaleDateString('en-CA')
        }
        items.age = window.calculateAge(items.dob);


        var errormessage = "";
        if (isNaN(items.age) || items.age < 0) {

            errormessage = "Invalid";

        } 
        if (errormessage) {

            error.current.showerror(errormessage); return;
        }


        props.callback?.apply(this, [items]);

    };
    useImperativeHandle(ref, () => ({
        setBasicinfo(profile) {
            setUserprofile(profile);

        },
    }));

    return (
        <div className="form-group">
            <ErrorDialog ref={error} />
            <form onSubmit={handleSubmit} className="form-group  col col-md-4 mx-auto">

                {< div className="mb-3 text-center">
                    {dateAdapter !== "th" && <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enUS} >
                        <label htmlFor="dob" className="form-label">{t("Date of Birth")}</label>
                        <div><DatePicker
                            defaultValue={userprofile.dob}
                            minDate={new Date(((new Date().getFullYear()) - 80).toString() + '-01-01')}
                            maxDate={new Date((new Date()).setFullYear( new Date().getFullYear() - 18 ))}
                            onChange={(newValue) => setValue(newValue)}
                            value={value}
                            slotProps={{ textField: { sx: { width: "100%" }, variant: 'outlined', size: 'small', id: "dob", name: "dob" } }}
                            label={t("dob")}
                            format="dd/MMM/yyyy"
                        /></div>

                    </LocalizationProvider>}
                    {dateAdapter == "th" && <LocalizationProvider dateAdapter={AdapterDateFnsBuddhist} adapterLocale={th} >
                        <label htmlFor="dob" className="form-label">{t("Date of Birth")}</label>
                        <div><DatePicker
                            defaultValue={userprofile.dob}
                            minDate={new Date(((new Date().getFullYear()) - 80).toString() + '-01-01')}
                            maxDate={new Date((new Date()).setFullYear( new Date().getFullYear() - 18 ))}
                            onChange={(newValue) => setValue(newValue)}
                            value={value}
                            slotProps={{ textField: { sx: { width: "100%" }, variant: 'outlined', size: 'small', id: "dob", name: "dob" } }}
                            id="dob" name="dob"
                            label={t("dob")}
        
                            format="dd/MMM/yyyy"
                        /></div>

                    </LocalizationProvider>}
                </div>
                }
                <hr />
                {<div className="mb-3 text-center">
                    <label className="form-label">{t("gender")}</label>
                    <br />
                    <MDBBtnGroup shadow='0' >
                        <MDBRadio btn outline="true" btnColor='light' id={'btn-male'+props.name} name={'gender'+props.name}  value="M" defaultChecked={userprofile.gender === "M"} label={<div><MDBIcon fas icon='male' className='me-2' />{t("Male")}</div>} />

                        <MDBRadio btn outline="true" btnColor='light' id={'btn-female'+props.name} name={'gender'+props.name} value="F" defaultChecked={userprofile.gender === "F"} label={<div><MDBIcon fas icon='female' className='me-2' />{t("Female")}</div>} />
                    </MDBBtnGroup>
                </div>
                }
                <div className="text-center">
                    <MDBBtn>{t("Submit")}</MDBBtn>
                </div>
            </form>
        </div>
    );
});



export default BasicInfo;