// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, OAuthProvider } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDsp6qkFbpFgM2jFXdd88XguimUdZkKSFU",
  authDomain: "simple-med-dialog.firebaseapp.com",
  projectId: "simple-med-dialog",
  storageBucket: "simple-med-dialog.appspot.com",
  messagingSenderId: "627782142314",
  appId: "1:627782142314:web:a5e41b71ad26780ce03d13",
  measurementId: "G-ZCLX3468W2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth();