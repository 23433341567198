import { configureStore } from '@reduxjs/toolkit'

const initialState = {

    lang: ""
}

function rootReducer(state = initialState, action) {
    if (action.type === "changeLanguage") {
        return { ...state, lang: action.payload }
    }
    return state;
}
export const store = configureStore({ reducer: rootReducer })