import React, { forwardRef, useImperativeHandle, useRef,useState } from "react";
import { useTranslation } from 'react-i18next';
import { MDBCollapse } from 'mdb-react-ui-kit';

const ErrorAlert = forwardRef((props, ref) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [timer, setTimer] = useState(null);
    const [alerttype, setAlerttype] = useState('alert-danger');
    
    const [error, setError] = useState("");
    
    const { t } = useTranslation('translations');
    const callbackfn = useRef();
    const handleClose = () => {
        setError("");
        setIsModalVisible(false);
        timer && clearTimeout(timer);
        callbackfn.current?.apply(this);

    };
    useImperativeHandle(ref, () => ({
        showerror(errormessage,callback)  {
            callbackfn.current =callback;
            setError(errormessage);
            setAlerttype("alert-danger")
            setIsModalVisible(true);
            setTimer(
                setTimeout(() => {
                    setIsModalVisible(false);
                    callbackfn.current?.apply(this);
                }, 3000)
            );
        },
        showmessage(message,callback)  {
            callbackfn.current =callback;
            setAlerttype("alert-primary")
            setError(message);
            setIsModalVisible(true);
            setTimer(
                setTimeout(() => {
                    setIsModalVisible(false);
                    callbackfn.current?.apply(this);
                }, 3000)
            );
        }
    }));
  
       return (
        <MDBCollapse open={isModalVisible} className="my-2">
        <div className={'alert alert-dismissible ' + alerttype} role="alert">
            <div dangerouslySetInnerHTML={
                    { __html: t(error, { interpolation: { escapeValue: false } }) }
                }></div>
       
        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={handleClose}></button>
      </div>
      </MDBCollapse>
    );
  });

  
  
  export default ErrorAlert;