import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react';
import {
    MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle
} from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';
import services from "../../helper/services";
import { useForkRef } from '@mui/material';
export default forwardRef((props, ref) => {
    const { t } = useTranslation('translations');

    const [item, setItem] = React.useState([]);
    const [selecteditem, setSelecteditem] = React.useState({});
    function onChange(e, el) {

        setSelecteditem(el);
        const newe = { target: e.target.parentNode, preventDefault: e.preventDefault };
        newe.target.value = el.value;
        if (props.onChange)
            props.onChange(newe);

    }
    function onKeyDown(e) {

    }
    function onClear() {

        setSelecteditem({});
        if (props.onChange)
            props.onChange({});
    }
    useImperativeHandle(ref, () => ({
        getResult: () => {
            return selecteditem;
        }
    }))

    useEffect(() => {
        const fetchData = async () => {
            var newvalue = await services.getListItem(t, props.list, '');
            setItem(newvalue);
            if (props.value) {
                setSelecteditem(newvalue.find(el => el.value === props.value))
            }
        }
        fetchData();

    }, []);


    return (<MDBDropdown >
        <MDBDropdownToggle outline className='form-outline' type="button" size={props.size} onKeyDown={(e) => { if (e.key === "Delete") onClear(e); }} >{selecteditem.value ? t(selecteditem.value) : t(props.label)}</MDBDropdownToggle>
        <MDBDropdownMenu data-field={props.dataField} style={{ margin: 0 }} >
            <MDBDropdownItem className='dropdown-item' onClick={onClear} >{t((props.label ? `select ${props.label}` : "select"))}</MDBDropdownItem>
            {item.map((el, key) => {

                return <MDBDropdownItem key={key} className='dropdown-item' name={props.name} onClick={(e) => onChange(e, el)} >{t(el.value)}</MDBDropdownItem>
            })}
        </MDBDropdownMenu>
    </MDBDropdown>
    );
});