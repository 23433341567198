import React, { forwardRef, useImperativeHandle, useRef, useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import "react-bootstrap-typeahead/css/Typeahead.css";
import {
    MDBBadge, MDBListGroup, MDBListGroupItem, MDBIcon, MDBBtn, MDBInput, MDBTooltip
    , MDBDatatable
} from 'mdb-react-ui-kit';
import ErrorDialog from './errordialog';
import services from "../../helper/services";
import moment from 'moment';
import { socket } from "../../helper/socket";

import { useSelector } from "react-redux";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AdapterDateFnsBuddhist from '../../helper/date-fns-be';
import { th, enUS } from 'date-fns/locale';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Historypersonalhealthgroup from './historypersonalhealthgroup'
import Confirmmodal from './confirmmodal'
import Resultmodal from './resultmodal'
import Dropdown from "./dropdown";

const component = forwardRef((props, ref) => {
    const navigate = useNavigate();
    const error = useRef();
    const confirm = useRef();
    const detail = useRef();
    const datatable = useRef();
    const department = useRef();
    const form = useRef();
    const [urgentcolor] = useState(["", "success", "warning", "danger"]);
    const { t } = useTranslation('translations');
    const [wait, setWait] = useState(false);
    const [result, setResult] = useState([]);
    const [normalRange, setNormalRange] = useState([]);

    const [value, setValue] = useState(new Date(moment().add(-1, 'd').format("YYYY-MM-DD")));
    const [value2, setValue2] = useState(new Date(moment().add(1, 'd').format("YYYY-MM-DD")));
    const dateAdapter = useSelector(state => state.lang);


    const [notiMessage, setNotiMessage] = useState({});

    useImperativeHandle(ref, () => ({
        updateList: (newitem) => {
            form?.current?.requestSubmit();
        }
    }))


    function handleChange(newValue) {
        setValue(newValue)

    }
    function handleChange2(newValue2) {
        setValue2(newValue2)

    }
    async function getHistory(data) {
        if (wait) return;
        setWait(true);
        try {
            var result = await services.searchResult(data, "admin");
            for (const item of result) {
                try {
                    //item.logic_result = JSON.parse(item.logic_result);
                    item.urgency = item.logic_result.urgency;
                } catch (ex) { }
            }
            setResult(result);
            setWait(false);

        } catch (e) {
            setWait(false);
            error.current.showerror(e);
        }

    }

    async function handleSubmit(e) {
        var errormessage = "";
        e.preventDefault();
        var form = (new FormData(e.target));
        getHistory({
            tel: form.get("search_tel"),
            email: form.get("search_email"),
            department: department.current.getResult()?.value ?? null,
            dateFromSearch: moment(value).format('YYYY-MM-DD'),
            dateToSearch: moment(value2).format('YYYY-MM-DD')
            , checkup: "P"
        });
    }

    async function updatestatus(msg) {
        var updatedresult = await services.searchResult({
            dateFromSearch: moment(value).format('YYYY-MM-DD'),
            dateToSearch: moment(value2).format('YYYY-MM-DD')
            , checkup: "P", id: msg.id
        }, "admin");
        if (updatedresult?.length > 0) {
            var newresult = result.map((x) => (x.id === updatedresult[0].id ? { ...updatedresult[0] } : x));
            setResult(newresult);
        }

    }

    useEffect(() => {
        if (notiMessage.flag) {
            if (notiMessage.flag == "updatestatus") {
                updatestatus(notiMessage.msg);
            }
            setNotiMessage({});
        }
    }, [notiMessage]);

    useEffect(() => {

        socket.on('updatestatus', (msg) => {
            setNotiMessage({ flag: "updatestatus", msg: msg })
        });

        const fetchData = async () => {
            var newvalue = await services.getListItem(t, "normalrange", '');
            setNormalRange(newvalue);

        }
        fetchData();
        getHistory({
            dateFromSearch: moment(value).format('YYYY-MM-DD'),
            dateToSearch: moment(value2).format('YYYY-MM-DD')
            , checkup: "P"
        });


    }, []);

    async function handleclick(row) {
        confirm.current.openModal(async (comment) => {
            await services.updatePersonalResult(row.id, { commentlist: comment["confirmhealthcheck"], comment: comment["item_other_confirmhealthcheck"] });
            /* var newresult = result.map((x) => (x.id === row.id ? { ...x, confirm: 1 } : x));

            setResult(newresult); */
        })
    }

    async function handledetailclick(row) {
        if (!row.commentobj) {
            const confirm_detail = await services.getconfirmdetail(row.id, "P");
            row.commentobj = {
                confirm_comment: {
                    confirmhealthcheck: confirm_detail.map(el => el.value)
                    , "item_other_confirmhealthcheck": row.confirm_comment
                }
            }
        }

        detail.current.openModal({
            ...row,
            ...row.commentobj
        })
    }
    const normalRangeFn = {};
    function calcNormalRange(item, value) {
        let ret = "";
        if (value == "") return ret;
        if (normalRangeFn[item]) {
            ret = normalRangeFn[item](value);
        }
        else {
            let _normalrange = [];

            _normalrange = normalRange;
            const normalRangeitem = _normalrange.find(el => el.name === item);
            if (normalRangeitem) {
                var binding = new Function('value', 'return ' + normalRangeitem.value);
                normalRangeFn[item] = binding;
                ret = binding(value);
            }
        }
        return ret;
    }

    function generateTree(obj) {
        var main = obj.map((row) => {
            return {
                ...row,
                department: (
                    <>
                        {t(row["departmentcode"])}
                    </>
                ),
                topic_note: (
                    <>
                        {t(row["topic_note"])}
                    </>
                ),
                createtime: (
                    <>
                        {t("resultdatetime", {
                            val: new Date(row["createtime"]), formatParams: {

                                val: {
                                    lng: (dateAdapter == "th" ? "th" : "en-GB"),
                                    day: 'numeric', month: 'short', year: 'numeric', hour: '2-digit',
                                    minute: '2-digit',
                                },
                            }
                        })}
                    </>
                ),
                blood_sugar: (<span className={calcNormalRange("blood_sugar", row.blood_sugar) === "" ? "" : "text-danger fw-bold"}>{row.blood_sugar}</span>),
                bp: (<><span className={calcNormalRange("sbp", row.sbp) === "" ? "" : "text-danger fw-bold"}>{row.sbp}</span>/<span className={calcNormalRange("dbp", row.dbp) === "" ? "" : "text-danger fw-bold"}>{row.dbp}</span></>),
                pulse: (<><span className={calcNormalRange("pulse", row.pulse) === "" ? "" : "text-danger fw-bold"}>{row.pulse}</span></>),
                comment: (<MDBTooltip tag='a' title={row.comment}>
                    {row.comment}
                </MDBTooltip >),
                called: (<>
                    {row.confirm && <MDBBtn onClick={() => handledetailclick(row)} outline size='sm' floating color="secondary" ><MDBIcon far icon={(row.confirm ? "check-" : "") + `circle`} size='2x' color="info" /></MDBBtn>}
                    {!row.confirm && <MDBBtn onClick={() => handleclick(row)} outline size='sm' floating color={(row.confirm ? "info" : "secondary")} ><MDBIcon far icon={(row.confirm ? "check-" : "") + `circle`} size='2x' color={(row.confirm ? "info" : "secondary")} /></MDBBtn>}
                </>)
            };
        });

        const resultData = {
            columns: [
                { label: t("confirmed"), field: 'called', sort: false  },
                { label: t('Result Date'), field: 'createtime' , sort: false },
                { label: t("HN"), field: 'hn' , sort: false },
                { label: t("department"), field: 'department', sort: false  },
                { label: t("Telephone"), field: 'tel' , sort: false },
                { label: t("Email"), field: 'email', sort: false  },
                { label: t("Name"), field: 'given_name' , sort: false },
                { label: t("Surname"), field: 'family_name', sort: false  },
                { label: t("bs_short"), field: 'blood_sugar', sort: false },
                { label: t("bp_short"), field: 'bp', sort: false  },

                { label: t("pulse_short"), field: 'pulse', sort: false  },
                { label: t("comment_short"), field: 'comment', sort: false  }

            ],
            rows: main
        }
        /*         if (props.checkup == "N")
                    resultData.columns.push({ label: t("urgencylevel"), field: 'urgencylevel' }) */

        resultData.columns = [...resultData.columns]

        //var items = group_time(obj);
        return <>
            <MDBDatatable ref={datatable} hover data={resultData} sortField='createtime' sortOrder='desc' rowsText={t("Rows per page:")} />
        </>

    }

    return (
        <div className="container-fluid">
            <ErrorDialog ref={error} />
            <Confirmmodal ref={confirm} remark="confirmhealthcheck" />
            <Resultmodal ref={detail} remark="confirmhealthcheck" />
            {<form ref={form} onSubmit={(e) => handleSubmit(e)}>
                < div className="row">
                    <div className="col"> <Dropdown label={("department")} list="department" ref={department} size="lg" /></div>
                    <div className="col"><MDBInput wrapperClass='h-100' label={t('Enter Email')} id='search_email' name="search_email" /></div>
                    <div className="col"><MDBInput wrapperClass='h-100' label={t('Enter Tel')} id='search_tel' name="search_tel" /></div>

                    {dateAdapter !== "th" && <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enUS} >

                        <div className="col"><DesktopDatePicker

                            minDate={new Date(((new Date().getFullYear()) - 150).toString() + '-01-01')}
                            maxDate={new Date()}
                            onChange={handleChange}
                            value={value}
                            slotProps={{ textField: { sx: { width: "100%" }, variant: 'outlined', size: 'small', id: "datefrom" } }}

                            format="dd MMM yyyy"
                        /></div>
                        <div className="col"><DesktopDatePicker

                            minDate={new Date(((new Date().getFullYear()) - 150).toString() + '-01-01')}
                            maxDate={new Date(moment().add(1, 'd').format("YYYY-MM-DD"))}
                            onChange={handleChange2}
                            value={value2}
                            slotProps={{ textField: { sx: { width: "100%" }, variant: 'outlined', size: 'small', id: "dateto" } }}

                            format="dd MMM yyyy"
                        />
                        </div>

                    </LocalizationProvider>}
                    {dateAdapter == "th" && <LocalizationProvider dateAdapter={AdapterDateFnsBuddhist} adapterLocale={th} >

                        <div className="col"><DesktopDatePicker

                            minDate={new Date(((new Date().getFullYear()) - 150).toString() + '-01-01')}
                            maxDate={new Date()}
                            onChange={handleChange}
                            value={value}
                            slotProps={{ textField: { sx: { width: "100%" }, variant: 'outlined', size: 'small', id: "datefrom" } }}

                            format="dd MMM yyyy"
                        /></div>
                        <div className="col"><DesktopDatePicker

                            minDate={new Date(((new Date().getFullYear()) - 150).toString() + '-01-01')}
                            maxDate={new Date(moment().add(1, 'd').format("YYYY-MM-DD"))}
                            onChange={handleChange2}
                            value={value2}
                            slotProps={{ textField: { sx: { width: "100%" }, variant: 'outlined', size: 'small', id: "dateto" } }}

                            format="dd MMM yyyy"
                        />
                        </div>

                    </LocalizationProvider>}
                    <div className="col">
                        <MDBBtn className='me-1' color='info' type="submit" disabled={wait}>
                            {t("search")}
                        </MDBBtn>
                    </div>
                </div>
            </form>
            }
            {result.length > 0 && normalRange.length > 0 && <>

                <div className="pt-4">
                    {generateTree(result)}
                </div>


            </>}
        </div>
    );
});



export default component;