import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import axios from 'axios';


const loadResources=async(locale)=> {
    return await axios.post(process.env.REACT_APP_DOMAIN+ 'api', { service:"getsmd-translation",lang: locale })
      .then((response) => { return response.data })
      .catch((error) => { console.log(error); });
}

const backendOptions = {
  loadPath: '{{lng}}|{{ns}}', 
  request: (options, url, payload, callback) => {
    try {
      const [lng] = url.split('|');
      loadResources(lng).then((response) => {
        callback(null, {
          data: response,
          status: 200, 
        });
      });
    } catch (e) {
      console.error(e);
      callback(null, {
        status: 500,
      });
    }
  },
};

i18n
  .use(LanguageDetector)
  .use(backend)
  .use(initReactI18next)
  .init({
    backend: backendOptions,
    fallbackLng: "en",
    debug: false,
    load:"languageOnly",
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false, 
    interpolation: {
      escapeValue: false, 
      formatSeparator: ","


    },
    react: {
 
    },
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
      
      lookupQuerystring: 'lang',        
      lookupCookie: 'i18n',
      lookupLocalStorage: 'i18nextLng',
    
      caches: ['localStorage', 'cookie'],
      excludeCacheFor: ['cimode'],
    
      cookieMinutes: 10,
      cookieDomain: 'myDomain',
    
      htmlTag: document.documentElement
    }
});

export default i18n;