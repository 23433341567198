import {
    MDBListGroup, MDBListGroupItem, MDBBadge,
    MDBInput
    , MDBInputGroup
    , MDBBtn
    , MDBIcon

}
    from 'mdb-react-ui-kit';
import React, { forwardRef, useImperativeHandle, useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';

import { useSelector } from "react-redux";
import Dropdown from "./dropdown";
import EntryMedication from "./entrymedication";
const EntryHNForm = forwardRef((props, ref) => {
    const { t, i18n } = useTranslation('translations');
    const [listHN, setListHN] = useState(props.hnList?.filter(e => e.name.toLowerCase() == 'hn') ?? []);
    const dateAdapter = useSelector(state => state.lang);
    const department = useRef();
    useImperativeHandle(ref, () => ({
        getResult: () => {
            return listHN;
        }
    }))




    function additem(e) {
        e.preventDefault();
        let item = { hn: document.getElementById("add_hn").value, alias: document.getElementById("add_hn_alias").value }
        if ((!item.hn) ||
            (!item.alias)) {
            props?.errorref?.current?.showerror("Invalid info");
            return;
        }
        if (listHN.some(el => el.hn == item.hn && el.alias == item.alias && el.department == department.current.getResult().value))
            props?.errorref?.current?.showerror("already added");
        const newlistHN = [...listHN];
        newlistHN.push({ hn: item.hn, alias: item.alias, department: department.current.getResult().value });
        setListHN(newlistHN);
        document.getElementById("add_hn").value = "";
        document.getElementById("add_hn_alias").value = "";
    }
    function changeitem(e) {
        e.preventDefault();
        let target = e.target;
        const newlistHN = [...listHN];
        newlistHN[target.parentNode.parentNode.getAttribute("data-item")][target.getAttribute("data-field")] = target.value;
        setListHN(newlistHN);
    }
    function removeitem(e, item) {
        e.preventDefault();
        let newlistHN = [...listHN];
        newlistHN = newlistHN.filter(el => el != item)
        setListHN(newlistHN);
    }



    return (<>
        <div className='mt-2'>

            <p className="pb-lg-2" style={{ color: '#3B4054' }}>{t('add hn')}</p>
        </div>
        <div className=' mb-3'>
            <MDBInputGroup className='custom-input-group'>
                <MDBInput label={t('Enter HN')} id='add_hn' maxLength={100} />

                <MDBInput label={t('Enter Alias')} id='add_hn_alias' maxLength={100} />
            </MDBInputGroup>
            <MDBInputGroup className='mb-3 custom-input-group'>
                <Dropdown label={("department")} list="department" ref={department} />
                <MDBBtn type="button" onClick={additem} ><MDBIcon fas icon="plus" /></MDBBtn>
            </MDBInputGroup>

            <EntryMedication />

        </div>
        {listHN.length > 0 && <h6 className='bg-light p-2 border-top border-bottom'>{t("listhn")}</h6>}
        <MDBListGroup light>
            {
                listHN.map((item, key) => {
                    return (<MDBListGroupItem className='' key={key}>
                        <div className="row">
                            <MDBInputGroup className='w-md-50 col-sm-12 col-xs-12"' data-item={key}>
                                <MDBInput label={t('Enter HN')} name={"listhn" + key} value={item.hn} data-field="hn" onChange={changeitem} maxLength={100} />
                                <MDBInput label={t('Enter Alias')} name={"listalias" + key} value={item.alias} data-field="alias" onChange={changeitem} maxLength={100} />
                            </MDBInputGroup>

                            <MDBInputGroup className='w-md-50 col-sm-12 col-xs-12"' data-item={key}>
                                <Dropdown label={("department")} name={"listdepartment" + key} key={key + item.hn + item.department} list="department" dataField="department" onChange={changeitem} value={item.department} />
                                <MDBBtn color='danger' outline type="button" onClick={(e) => removeitem(e, item)}><MDBIcon fas icon="minus" /></MDBBtn>
                            </MDBInputGroup>
                        </div>

                    </MDBListGroupItem>)
                })
            }
        </MDBListGroup></>)
});

export default EntryHNForm;