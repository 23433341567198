import React from 'react';
import {
    MDBIcon
} from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';
function getStarCount(value) {
    switch (true) {
        case (value < 20):
            return 1;
        case (value < 40):
            return 2;
        case (value < 60):
            return 3;
        case (value < 80):
            return 4;
        case (value <= 100):
            return 5;
        default:
            return 0;

    }
}
export default function ScoreRating(props) {
    const { t } = useTranslation('translations');
    let score = props.value;
    const stars = [];
    const star_count = getStarCount(score);
    for (let i = 1; i <= star_count; i++) {
        stars.push(<MDBIcon fas icon="star" key={i} color={props.color??"success"}  size='sm' />);
    }

    return (<>
        {stars.length > 0 && <span className='mx-1'>{stars}</span>}
    </>
    );
}