import React, { useState, useRef, useEffect } from "react";
import Menu from './sub/appbar';
import {
    MDBCollapse,

} from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';
import Checkuplist from './sub/checkuplist'
import Symptopcheckerlist from './sub/symptomcheckerlist'
import BasicinfoComponent from "./sub/basicinfo";
import { useNavigate } from 'react-router-dom';
import services from '../helper/services';
import ErrorDialog from './sub/errordialog';
import DisClaimerDialog from './sub/disclaimerdialog';

export default function Main() {
    const navigate = useNavigate();
    const [expanded, setExpanded] = useState(false);
    const { t } = useTranslation('translations');
    const [otherprofile, setOtherprofile] = useState({});
    const [userprofile, setUserprofile] = useState({});


    const updateOtherprofile = function (e) {
        e && setOtherprofile(e);
    }


    const error = useRef();
    async function fetchuserprofile() {

        try {
            var res = await services.fetchuserprofile()
            setUserprofile(res)
        } catch (e) {
            error.current?.showerror(e);
        }

    }
    const updateUserprofile = async function (e) {
        if (e) {
            try {
                var res = await services.updateProfile(e);
                setUserprofile(e);
                //error.current.showmessage(res)
            } catch (e) {
                error.current.showerror(e)
            }

        }

    }
    const [s_showDiv, setS_showDiv] = useState((process.env.REACT_APP_IMPLEMENT == "HOSPITAL" ?"":"C"));

    const [ns_showDiv, setNs_showDiv] = useState((process.env.REACT_APP_IMPLEMENT == "HOSPITAL" ?"":"C"));

    const handleRadioChange = (e) => {
        document.getElementById("collapaseexpandcontainer").style.height = null;
        if (e.target.id == "ns_chkup_topic" || e.target.id == "ns_symptom_topic") {
            setS_showDiv("")
            setNs_showDiv(e.target.getAttribute("data-value"));
        }
        else {
            setNs_showDiv("");
            setS_showDiv(e.target.getAttribute("data-value"));
        }
    };
    const handleExpandClick = (e) => {
        e.preventDefault();
        setExpanded(!expanded);
    };

    useEffect(() => {
        if (window.sessionStorage.getItem("token"))
            fetchuserprofile();
    }, []);
    return (<>
        <Menu requirelogin="true" />

        <div className="container">
            <section className='p-md-4 py-4'>
                <div className=" text-center pt-2 pb-2">
                    <ErrorDialog ref={error} />
                    <DisClaimerDialog />
                    <div className="justify-content-center">
                        <div className="bg-light shadow">
                            <div className="row mb-2">
                                <div className="col my-3">
                                    <h5 className="border-bottom border-1" style={{ color: '#2C2F78' }}>
                                        <i className="fas fa-user-alt m-2" style={{ fontSize: '20px', color: '#2C2F78' }}></i>{t("Self assessment")}</h5>
                                </div>
                            </div>
                            {!userprofile.gender && <div className="row mb-4">
                                <BasicinfoComponent callback={updateUserprofile} />
                            </div>}
                            <div className="container-fluid" >
                                {userprofile.gender && <div className="row">

                                    <div className="col mb-2">

                                        <label className="">
                                            <div className={`btn btn-lg btn${s_showDiv === "C" ? "-primary" : "-secondary"}`} style={{ borderRadius: '14px', border: 'none', fontSize: '16px', fontWeight: 'bold' }}>
                                                <input type="radio" className="form-check-input d-none" id="s_chkup_topic" name="s_assessment_topic" value="s_chkup_topic" data-value="C"
                                                    checked={s_showDiv === "C"}
                                                    onChange={handleRadioChange}
                                                />
                                                {t("Check up: ")}
                                            </div>
                                        </label>

                                    </div>
                                    {process.env.REACT_APP_IMPLEMENT == "HOSPITAL" && <div className="col">
                                        <label className="">
                                            <div className={`btn btn-lg btn${s_showDiv === "S" ? "-primary" : "-secondary"}`} style={{ borderRadius: '14px', border: 'none', fontSize: '16px', fontWeight: 'bold' }}>
                                                <input type="radio" className="form-check-input d-none" id="s_symptom_topic" name="s_assessment_topic" value="s_symptom_topic" data-value="S"
                                                    checked={s_showDiv === "S"}
                                                    onChange={handleRadioChange} />
                                                {t("Symptom: ")}

                                            </div>
                                        </label>
                                    </div>}

                                </div>}
                            </div>
                            <div className="row p-4">

                                {s_showDiv === "C" && userprofile.gender && <Checkuplist self={true} />}
                                {s_showDiv === "S" && userprofile.gender && <Symptopcheckerlist self={true} />}
                            </div>
                        </div>
                        <div className="row my-4">
                            <div className="col">
                                <hr style={{ border: '1px solid #61677D' }} />
                            </div>
                            <div className="col">
                                <h6>{t("OR")}</h6>
                            </div>
                            <div className="col">
                                <hr style={{ border: '1px solid #61677D' }} />
                            </div>
                        </div>
                        <div className="bg-light shadow">
                            <div className="row">
                                <div className="col my-3" onClick={handleExpandClick}
                                >
                                    <h5 className="border-bottom border-1" style={{ color: '#2C2F78' }}>
                                        <i className="fas fa-users m-2" style={{ fontSize: '20px', color: '#2C2F78' }}></i>{t("Not-self assessment")}</h5>
                                    <i className={"fas m-1 fa-angle-" + (expanded ? "up" : "down")} style={{ fontSize: '20px', color: '#2C2F78' }}></i>
                                </div>
                            </div>
                            <MDBCollapse id="collapaseexpandcontainer" open={expanded}>
                                <div className="container-fluid" id="collapasecontainer">
                                    {!otherprofile.gender && <div className="row mb-4">
                                        <BasicinfoComponent callback={updateOtherprofile} name="other" />
                                    </div>}
                                    {otherprofile.gender && <div className="row">
                                        <div className="col mb-2">
                                            <label className="">
                                                <div className={`btn btn-lg btn${ns_showDiv === "C" ? "-primary" : "-secondary"}`} style={{ borderRadius: '14px', border: 'none', fontSize: '16px', fontWeight: 'bold' }}>

                                                    <input type="radio" className="form-check-input d-none" id="ns_chkup_topic" name="s_assessment_topic" value="ns_chkup_topic" data-value="C"
                                                        checked={ns_showDiv === "C"}
                                                        onChange={handleRadioChange}
                                                    />
                                                    {t("Check up: ")}

                                                </div>
                                            </label>
                                        </div>
                                        {process.env.REACT_APP_IMPLEMENT == "HOSPITAL" && <div className="col mb-2">
                                            <label className="">
                                                <div className={`btn btn-lg btn${ns_showDiv === "S" ? "-primary" : "-secondary"}`} style={{ borderRadius: '14px', border: 'none', fontSize: '16px', fontWeight: 'bold' }}>

                                                    <input type="radio" className="form-check-input d-none" id="ns_symptom_topic" name="s_assessment_topic" value="ns_symptom_topic" data-value="S"
                                                        checked={ns_showDiv === "S"}
                                                        onChange={handleRadioChange} />
                                                    {t("Symptom: ")}

                                                </div>
                                            </label>
                                        </div>}
                                    </div>}
                                    <div className="row p-4">

                                        {otherprofile.gender && ns_showDiv === "C" && <Checkuplist self={false} userprofile={otherprofile} />}
                                        {otherprofile.gender && ns_showDiv === "S" && <Symptopcheckerlist self={false} userprofile={otherprofile} />}
                                    </div>
                                </div>
                            </MDBCollapse>
                        </div>

                        {userprofile?.userproperties?.filter(el => el.name == "hn").length > 0 && <><div className="row my-4">
                            <div className="col">
                                <hr style={{ border: '1px solid #61677D' }} />
                            </div>
                        </div>
                            <div className="row"><button type="button" className="btn btn-link btn-lg" onClick={() => navigate("/personalhealth")}>{t("Personal Health")}</button></div>
                        </>}
                        <div className="row my-4">
                            <div className="col">
                                <hr style={{ border: '1px solid #61677D' }} />
                            </div>
                        </div>
                        <div className="row"><button type="button" className="btn btn-link btn-lg" onClick={() => navigate("/history")}>{t("View history")}</button></div>
                    </div>

                </div>
            </section>
        </div >

    </>
    );
}