import React, { useState, useRef, useEffect, forwardRef } from "react";

import {
    MDBContainer, MDBInput
    , MDBDatepicker
    , MDBInputGroup,
    MDBCheckbox,
    MDBRadio, MDBBtn, MDBCollapse, MDBIcon, MDBSelect, MDBTextArea
} from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import services from '../../helper/services';
import ErrorDialog from './errordialog';
import { useSelector } from "react-redux";

import Multipleoption from './multipleoption'


const component = forwardRef((props, ref) => {
    const [expanded, setExpanded] = useState(false);
    const { state } = useLocation();
    let [selected, setSelected] = useState(null);
    const navigate = useNavigate();
    const error = useRef();
    const underlying = useRef();
    const familyhistory = useRef();
    const healthrisk = useRef();
    const medtherapy = useRef();
    const symptoms = useRef();
    const answer = useRef([]);
    const { t } = useTranslation('translations');
    const [page, setPage] = useState();
    const handleKeyDown = function (e) {
        e.target.style.height = 'inherit';
        e.target.style.height = `${e.target.scrollHeight}px`;
        // In case you have a limitation
        // e.target.style.height = `${Math.min(e.target.scrollHeight, limit)}px`;
    }

    useEffect(() => {
        var result = {}
        props.items.forEach(el => result[el.name] = el.value);
        setPage(result);
    }, [])


    return (<>
        <div className="container p-0">
            <section className='pt-md-4 p-md-4'>
                <ErrorDialog ref={error} />

                {page &&
                    <>
                        <div className="mx-0 mx-sm-auto">
                            <form className="">
                                <div className="">
                                    <div className="" action="" autoComplete="off">
                                        <div className="col">
                                            {!["6"].includes(page.version) && <div className="row">
                                                <div className="col col-md-10">
                                                    {page.minimal != "minimal" && <p className="fw-bold mb-2">{t("body measurement")}</p>}
                                                    <div className="ms-md-3 mb-3">
                                                        {page.weight && <div className="form-outline mb-3" style={{ maxWidth: "22rem" }}>
                                                            <MDBInput
                                                                readonly
                                                                type="number"
                                                                name="weight"
                                                                label={t("weight")}
                                                                defaultValue={page.weight}

                                                            />
                                                        </div>}

                                                        {page.height && <div className="form-outline mb-3" style={{ maxWidth: "22rem" }}>
                                                            <MDBInput
                                                                readonly
                                                                type="number"
                                                                name="height"
                                                                label={t("height")}
                                                                defaultValue={page.height}
                                                            />
                                                        </div>}
                                                        <input type="hidden" name="bmi" />
                                                        {(page.minimal != "minimal" || ["6"].includes(page.version)) && <div className="form-outline mb-3" style={{ maxWidth: "22rem" }}>
                                                            <MDBInput
                                                                readonly
                                                                type="number"
                                                                name="waist"
                                                                label={t("waist circumference (inch)")}
                                                                defaultValue={page.waist}
                                                            />
                                                        </div>}
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                            {page.Symptoms && <> {page.minimal != "minimal" && <hr />}
                                                <div className="row">
                                                    <div className="col col-md-10">
                                                        {page.minimal != "minimal" && <p className="fw-bold mb-2">{t("currentsymptoms")}</p>}
                                                        <div className="ms-md-3 mb-3" style={page.version == 5 ? { display: "none" } : {}}>
                                                            <Multipleoption name="Symptoms" label="currentsymptoms" ref={symptoms} defaultValue={page} readonly />
                                                        </div>
                                                    </div>
                                                </div></>}
                                            {page.underlying && <>{page.minimal != "minimal" && <hr />}
                                                <div className="row">
                                                    <div className="col col-md-10">
                                                        {(page.minimal != "minimal" || ["6"].includes(page.version)) && <p className="fw-bold mb-2">{t("Underlying")}</p>}
                                                        <div className="ms-md-3 mb-3">
                                                            <Multipleoption name="underlying" label="Underlying" ref={underlying} defaultValue={page} readonly ischeckbox={["6"].includes(page.version)} />
                                                        </div>
                                                    </div>
                                                </div></>}
                                            {page.familyhistory && <> {(page.minimal != "minimal" || (["6"].includes(page.version)) && page.underlying) && <hr />}
                                                <div className="row">
                                                    <div className="col col-md-10">
                                                        {page.minimal != "minimal" || ["6"].includes(page.version) && <p className="fw-bold mb-2">{t("Family History")}</p>}
                                                        <div className="ms-md-3 mb-3">
                                                            <Multipleoption name="familyhistory" label="Family History" ref={familyhistory} defaultValue={page} readonly ischeckbox={["6"].includes(page.version)} />
                                                        </div>
                                                    </div>
                                                </div></>}
                                            {page.healthrisk && <> {(page.minimal != "minimal" || (["6"].includes(page.version)) && (page.underlying || page.familyhistory)) && <hr />}
                                                <div className="row">
                                                    <div className="col col-md-10">
                                                        {page.minimal != "minimal" || ["6"].includes(page.version) && <p className="fw-bold mb-2">{t("health risk")}</p>}
                                                        <div className="ms-md-3 mb-3">
                                                            <Multipleoption name="healthrisk" label="health risk" ref={healthrisk} defaultValue={page} readonly ischeckbox={["6"].includes(page.version)} />
                                                        </div>
                                                    </div>
                                                </div></>}
                                            {page.medtherapy && <> {page.minimal != "minimal" && <hr />}
                                                <div className="row">
                                                    <div className="col col-md-10">
                                                        {page.minimal != "minimal" && <p className="fw-bold mb-2">{t("medtherapy")}</p>}
                                                        <div className="ms-md-3 mb-3">
                                                            <Multipleoption name="medtherapy" label="medtherapy" ref={medtherapy} defaultValue={page} readonly />
                                                        </div>
                                                    </div>
                                                </div></>}
                                            {["6"].includes(page.version) && <> {(page.underlying || page.familyhistory || page.healthrisk) && <hr />}<div className="row">
                                                <div className="col col-md-10">
                                                    {page.minimal != "minimal" || ["6"].includes(page.version) && <p className="fw-bold mb-2">{t("body measurement")}</p>}
                                                    <div className="ms-md-3 mb-3">
                                                        {page.weight && <div className="form-outline mb-3" style={{ maxWidth: "22rem" }}>
                                                            <MDBInput
                                                                readonly
                                                                type="number"
                                                                name="weight"
                                                                label={t("weight")}
                                                                defaultValue={page.weight}

                                                            />
                                                        </div>}

                                                        {page.height && <div className="form-outline mb-3" style={{ maxWidth: "22rem" }}>
                                                            <MDBInput
                                                                readonly
                                                                type="number"
                                                                name="height"
                                                                label={t("height")}
                                                                defaultValue={page.height}
                                                            />
                                                        </div>}
                                                        <input type="hidden" name="bmi" />
                                                        {(page.minimal != "minimal" || ["6"].includes(page.version)) && <div className="form-outline mb-3" style={{ maxWidth: "22rem" }}>
                                                            <MDBInput
                                                                readonly
                                                                type="number"
                                                                name="waist"
                                                                label={t("waist circumference (inch)")}
                                                                defaultValue={page.waist}
                                                            />
                                                        </div>}
                                                    </div>
                                                </div>
                                            </div>
                                            </>
                                            }
                                            {page.minimal == "minimal" && page.checkupother && <> {["6"].includes(page.version) && <hr />}<div className="row">
                                                <div className="col col-md-10">
                                                    {["6"].includes(page.version) && <p className="fw-bold mb-2">{t("other header")}</p>}
                                                    <div className="ms-md-3 mb-3">
                                                        <div className="form-outline mb-3" style={{ maxWidth: "22rem" }}>

                                                            <MDBTextArea
                                                                disabled
                                                                onFocus={handleKeyDown}
                                                                name="checkupother"
                                                                label={t(page.version == 5 ? "other_nlp" : "other")}
                                                                defaultValue={page.checkupother}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div></>}
                                        </div>
                                    </div>
                                </div>

                            </form >
                        </div >

                    </>

                }
            </section >
        </div >


    </>
    );
})

export default component;