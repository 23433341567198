window.calculateAge = function (dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}
window.calculateAgeMonth = function (dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        return 0;
    }
    else
        return m;
}

window.calculateBMI = function (weight, height) {
    return (weight / ((height * height)
        / 10000)).toFixed(2);
}

window.parseDate = function (s, dateadapter) {
    if (!s) return;
    var months = {
        jan: 0, feb: 1, mar: 2, apr: 3, may: 4, jun: 5,
        jul: 6, aug: 7, sep: 8, oct: 9, nov: 10, dec: 11,
        "ม.ค.": 0,
        "ก.พ.": 1,
        "มี.ค.": 2,
        "เม.ย.": 3,
        "พ.ค.": 4,
        "มิ.ย.": 5,
        "ก.ค.": 6,
        "ส.ค.": 7,
        "ก.ย.": 8,
        "ต.ค.": 9,
        "พ.ย.": 10,
        "ธ.ค.": 11,

    };
    var p = s.split('/');
    return new Date(dateadapter == "th" ? parseInt(p[2]) - 543 : p[2], months[p[1].toLowerCase()], p[0]);
}

var old_log = console.log;
console.logitem = function () {
    function clearProto(obj) {
        if (obj && typeof (obj) === "object") {
            var temp = JSON.parse(JSON.stringify(obj));
            temp.__proto__ = null;
            for (var prop in temp)
                temp[prop] = clearProto(temp[prop]);
            return temp;
        }
        return obj;
    }
    for (var i = 0; i < arguments.length; ++i)
        arguments[i] = clearProto(arguments[i]);
    old_log.apply(console, arguments);
}
window.skipitem = undefined;
window.unCheckAll = function (skipitem) {
    var x = document.querySelectorAll("input[type='checkbox']");
    if (skipitem) window.skipitem = skipitem;
    for (var o of x) {

        o.checked = false;
        o.skipitem = skipitem;
        o.removeEventListener('click', window.unCheckSkip)
        o.addEventListener('click', window.unCheckSkip)
    }
    var node = document.querySelector(`input[name='${window.skipitem}']`);
    if (node && skipitem) node.checked = true;
}

window.unCheckSkip = function (e) {
    var skipitem = e.currentTarget.skipitem;
    if (!skipitem) return;
    var node = document.querySelector(`input[name='${skipitem}']`);
    if (node) node.checked = false;
}
Object.byString = function (o, s) {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
}

window.subItems = function (parentItemName) {
    var node = document.querySelectorAll("input[name='" + parentItemName + "']");
    if (node.length == 0) return;
    node = node[0];
    var a = document.querySelectorAll("input[parentitemname='" + parentItemName + "']");
    for (var x of a) {
        if (node.checked) { }
        else {
            if (x.checked) x.click();

        }
    }
    var b = document.querySelectorAll("div[parentitemname='" + parentItemName + "']");
    for (var x of b) {
        if (node.checked)
            x.style.display = 'block';
        else
            x.style.display = 'none';
    }
}

window.subItemsid = function (parentItemId) {
    var node = document.querySelectorAll("input[id='" + parentItemId + "']");
    if (node.length == 0) return;
    node = node[0];
    var a = document.querySelectorAll("input[parentitemid='" + parentItemId + "']");
    for (var x of a) {
        if (node.checked) { }
        else {
            if (x.checked) x.click();

        }
    }
    var b = document.querySelectorAll("div[parentitemid='" + parentItemId + "']");
    for (var x of b) {
        if (node.checked)
            x.style.display = 'block';
        else
            x.style.display = 'none';
    }
}

window.toBuddhistYear = function (moment, format) {
    var christianYear = moment.format('YYYY')
    var buddhishYear = (parseInt(christianYear) + 543).toString()
    return moment
        .format(format.replace('YYYY', buddhishYear).replace('YY', buddhishYear.substring(2, 4)))
        .replace(christianYear, buddhishYear);
}



var ua = navigator.userAgent || navigator.vendor || window.opera;

function isFacebookApp() {
    return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1);

}

function isLineApp() {
    return (ua.indexOf("Line") > -1);

}

window.isinAppBrowser = function () {
    if (isLineApp() || isFacebookApp()) return true;
    else return false;
}

