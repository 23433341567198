//External imports
import React, { forwardRef, useEffect, useRef } from "react";



const loadScript = (src) =>
    new Promise((resolve, reject) => {
        if (document.querySelector(`script[src="${src}"]`)) return resolve()
        const script = document.createElement('script')
        script.src = src
        script.onload = () => resolve()
        script.onerror = (err) => reject(err)
        document.body.appendChild(script)
    })

const GoogleAuth = forwardRef((props, ref) => {


    const googleButton = useRef();

    useEffect(() => {
        const src = 'https://accounts.google.com/gsi/client'
        const id = process.env.REACT_APP_GOOGLELOGIN

        loadScript(src)
            .then(() => {
                /*global google*/
                //console.log(google)
                google.accounts.id.initialize({
                    client_id: id,
                    callback: handleCredentialResponse,
                    auth_url: "https://accounts.google.com/o/oauth2/auth"
                })
                google.accounts.id.renderButton(
                    googleButton.current,
                    { theme: 'outline', 'longtitle': true, size: 'large' }
                )
            })
            .catch(console.error)

        return () => {
            const scriptTag = document.querySelector(`script[src="${src}"]`)
            if (scriptTag) document.body.removeChild(scriptTag)
        }
    }, [])

    function handleCredentialResponse(response) {
        if (props.onSuccess)
            props.onSuccess(response)
    }

    return (
        <div ref={googleButton}></div>
    )
})

export default GoogleAuth