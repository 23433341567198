import React from 'react';
import {
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn
} from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';

export default function Complete(props) {
  const { t } = useTranslation('translations');
  return (
    <MDBCard alignment='center' >
      <MDBCardBody>
        <MDBCardTitle>{t(props.title)}</MDBCardTitle>
        <MDBCardText>{t(props.desc)}</MDBCardText>
        {props.subdesc && <MDBCardText className='text-danger'>{t(props.subdesc)}</MDBCardText>}
        <MDBBtn onClick={props.callback}>{t(props.btn)}</MDBBtn>
      </MDBCardBody>
    </MDBCard>
  );
}