import './App.css';
import './i18n';
import { withTranslation } from 'react-i18next'
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Login from "./components/login";
import Main from "./components/main";
import Register from "./components/register";
import Verify from "./components/verify";
import Forgotpassword from "./components/forgotpassword";
import Changepassword from "./components/changepassword";
import Manageuser from "./components/manageuser"
import Symptomchecker from "./components/symptomchecker"
import Result from "./components/result"
import History from "./components/history"
import AdminHistory from "./components/adminhistory"
import Checkup from "./components/checkup"
import Legal from "./components/legal"
import CheckupResult from "./components/checkupresult"
import Personalhealth from "./components/personalhealth"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { GoogleOAuthProvider } from '@react-oauth/google';

import React, { useState, useRef } from 'react';

function App({ t }) {
  window.Buffer = window.Buffer || require("buffer").Buffer;
  const [token, setToken] = useState(window.sessionStorage.getItem("token") ?? "");

  return (

    <>
      {/* This is the alias of BrowserRouter i.e. Router */}
      <Router>

        <Routes>

          { <Route exact path="/" element={<Main />} /> }
          <Route exact path="/login" element={ <Login />} />
          <Route exact path="/index" element={<Main />} />
          <Route exact path="/legal" element={<Legal />} />
          <Route exact path="/history" element={<History />} />
          <Route exact path="/admin/history" element={<AdminHistory />} />
          <Route exact path="/personalhealth" element={<Personalhealth />} />
          <Route exact path="/manageuser" element={<Manageuser />} />
          <Route exact path="/checkup/" element={<Checkup />} />
          <Route exact path="/checkup/:minimal" element={<Checkup />} />
          <Route exact path="/checkup/:minimal/:self" element={<Checkup />} />
          <Route exact path="/checkup2/:minimal/:version" element={<Checkup />} />
          <Route exact path="/checkup2/:minimal/:version/:self" element={<Checkup />} />
          <Route exact path="/symptomchecker/:id" element={<Symptomchecker />} />
          <Route exact path="/symptomchecker/:id/:self" element={<Symptomchecker />} />

          <Route exact path="/result/:id" element={<Result />} />
          <Route exact path="/checkupresult/:id" element={<CheckupResult />} />

          <Route exact path="/forgotpassword" element={<GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA}><Forgotpassword /></GoogleReCaptchaProvider>} />
          <Route exact path="/changepassword" element={<GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA}><Changepassword /></GoogleReCaptchaProvider>} />
          <Route exact path="/verify" element={<GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA}><Verify /></GoogleReCaptchaProvider>} />
          <Route exact path="/register" element={<GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA}><Register /></GoogleReCaptchaProvider>} />
          <Route exact path="/externalregister" element={<GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA}><GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLELOGIN}><Register key="external" /></GoogleOAuthProvider></GoogleReCaptchaProvider>} />
          <Route path="*" element={<div>Not Found or You do not have permission.</div>} />
        </Routes>
      </Router>
    </>

  );
}

export default withTranslation()(App)