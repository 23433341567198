import React, { forwardRef, useRef, useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ScoreRating from './scorerating';
import {
    MDBIcon, MDBCollapse

} from 'mdb-react-ui-kit';



const component = forwardRef((props, ref) => {
    const navigate = useNavigate();
    const [urgentcolor] = useState(["", "success", "warning", "danger"]);
    const error = useRef();
    const [expanded, setExpanded] = useState(false);
    const { t, i18n } = useTranslation('translations');
    const handleExpandClick = (e) => {
        e.preventDefault();
        setExpanded(!expanded);

    };
    const object = props.object;
    function translate(list) {
        var result = "";
        result = list.map(el => t(el, { interpolation: { escapeValue: false } })).join(" ");
        return result;
    }
    return (
        <React.Fragment>
            {(object.codeArr || object.code || object.comment || object.commentArr) &&
                <div>{false && <MDBIcon fas icon="bookmark" className={"me-2" + " text-" + urgentcolor[object.level]} onClick={handleExpandClick} />}
                    {object.code && <span onClick={handleExpandClick}>
                        <span className="" data-title={(object.code || object.comment)}>{t(object.code || object.comment)}</span>
                        {!object.disablescore && <ScoreRating value={object.score} color={urgentcolor[object.level]} />}
                        {object.code && (object.comment || object.commentArr) && <i className={"far ms-1 text-secondary fa-square-" + (!expanded ? "plus" : "minus")} style={{ fontSize: "14px" }}></i>}</span>}

                    {object.codeArr && object.codeArr.length > 0 && <span onClick={handleExpandClick}>
                        <span className=""  data-title={(object.codeArr)} dangerouslySetInnerHTML={
                            { __html: translate(object.codeArr) }
                        }></span>
                        {!object.disablescore && <ScoreRating value={object.score} color={urgentcolor[object.level]} />}
                        {object.codeArr && (object.comment || object.commentArr) && <i className={"far ms-1 text-secondary fa-square-" + (!expanded ? "plus" : "minus")} style={{ fontSize: "14px" }}></i>}</span>}


                    {(object.code || object.codeArr) && (object.comment || object.commentArr) &&
                        <MDBCollapse open={expanded} key={i18n.language.toLowerCase()}>
                            {!(object.commentArr && object.commentArr.length > 0) && <p className="text-muted" style={{ fontSize: "14px" }}><i>{t(object.comment)}</i></p>}
                            {object.commentArr && object.commentArr.length > 0 && <p className="text-muted" style={{ fontSize: "14px" }} dangerouslySetInnerHTML={
                                { __html: translate(object.commentArr) }
                            }></p>}
                        </MDBCollapse>}
                </div>
            }
        </React.Fragment>
    );
});





export default component;